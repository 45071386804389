import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { remCalc, colors, position, gapable } from '../../helpers/stylehelpers';
import check from '../../images/icons/check.svg';

/** @type {object} Props */
const propTypes = {
    className: PropTypes.string,
    field: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }).isRequired,
    label: PropTypes.node.isRequired,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    form: PropTypes.shape({
        errors: PropTypes.object,
        touched: PropTypes.object,
    }).isRequired,
    type: PropTypes.string.isRequired,
};

/** @type {object} DefaultProps */
const defaultProps = {
    className: null,
    gap: null,
};

/** @type {styleComponent} Wrapper um die Komponente */
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    ${gapable({
        small: 'l',
        medium: 'none',
    })};

    ${({ highlightError }) =>
        highlightError &&
        css`
            &,
            a {
                color: ${colors.white};
            }
            &:after {
                ${position({ top: '-6px', left: '-7px' })};
                padding: 0.25em;
                content: '';
                display: block;
                width: calc(100% + 14px);
                height: calc(100% + 12px);
                background-color: ${colors.alert};
                z-index: 0;
            }
        `};
`;

/** @type {styleComponent} Label */
const Label = styled.label`
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    font-size: ${remCalc(18)};
`;

/** @type {styleComponent} Wrapper für FauxCheck und Checkbox  */
const FieldWrapper = styled.div`
    position: relative;
    width: ${remCalc(20)};
    height: ${remCalc(20)};
    margin-right: 0.5em;
`;

/** @type {css} Geteilte Styles  */
const boxStyles = css`
    ${position({ top: '0', left: '0' })};
    width: 100%;
    height: 100%;
`;

/** @type {styleComponent} Das eigentliche Input  */
const CheckboxElement = styled.input.attrs({ type: 'checkbox' })`
    ${boxStyles};
    z-index: 2;
    opacity: 0;

    &:checked + div {
        background-image: url(${check});
    }
`;

/** @type {styleComponent} Das eigentliche Input  */
const RadioElement = styled.input.attrs({ type: 'radio' })`
    ${boxStyles};
    position: relative;
    opacity: 0;
    z-index: 2;

    &:checked + div {
        &:before {
            content: '';
            display: block;
            border-radius: 50%;
            background-color: ${colors.blue};
            ${position({ top: '50%', left: '50%' })};
            transform: translate(-50%, -50%);
            height: 60%;
            width: 60%;
        }
    }
`;

/** @type {styleComponent} Falsche Checkbox */
const FauxRadio = styled.div`
    ${boxStyles};
    border: 1px solid ${colors.gray};
    background-color: ${colors.white};
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center center;
`;

/** @type {styleComponent} Falsche Checkbox */
const FauxCheck = styled.div`
    ${boxStyles};
    border: 1px solid ${colors.gray};
    background-color: ${colors.white};
    background-repeat: no-repeat;
    background-position: center center;
`;

/**
 * Input Komponente
 * ----------------------------------------------------------------------------
 * Stellt (Formik)-Input Elemente zur Verfügung
 * @param {string} props.name Name des Feldes
 * @param {string} props.field Formik Field
 * @param {object} props.error Fehler aus dem Formik-Formular
 * @param {object} props.touched Angefasste Felder aus Formik-Formular
 * @param {string} props.className Optional: Klassenname
 * @param {string} props.label Label des Elements
 * @param {string} props.gap Optional: Label des Elements
 *
 * @example <Checkbox name="foo" value="bar"/>
 */
const CheckboxOrRadio = ({
    field: { name, value },
    form: { errors, touched },
    form,
    className,
    label,
    gap,
    type,
}) => {
    const errorOccured = errors[name] && touched[name];

    // Entscheiden, welche Elemente benötigt werden
    const FieldElement = type === 'checkbox' ? CheckboxElement : RadioElement;
    const FauxElement = type === 'checkbox' ? FauxCheck : FauxRadio;

    /**
     * Prüft ab, ob Checkbox aktiv
     */
    const checkBoxChecked = Array.isArray(form.values[name])
        ? form.values[name].indexOf(value) >= 0
        : form.values[name];

    /**
     * Prüft, ob Radio aktiv ist
     */
    const radioChecked = form.values[name] === value;

    /**
     * onChange Handling für Checkbox
     * @param {evt} changeEvent
     */
    const checkBoxOnChanged = evt => {
        const formValue = form.values[name];
        form.setFieldTouched(name, true);

        if (!Array.isArray(formValue)) {
            return form.setFieldValue(name, evt.target.checked ? value : null);
        }

        if (formValue.includes(value)) {
            return form.setFieldValue(name, formValue.filter(el => el !== value));
        }
        return form.setFieldValue(name, [...formValue, value]);
    };

    /**
     * onChange für Radios
     */
    const radioOnChange = () => {
        form.setFieldTouched(name, true);
        return form.setFieldValue(name, value);
    };

    // Entscheiden, welche Funktionen benötigt werden
    const onChangeFunc = type === 'checkbox' ? checkBoxOnChanged : radioOnChange;
    const checked = type === 'checkbox' ? checkBoxChecked : radioChecked;

    return (
        <Wrapper gap={gap} className={className} highlightError={errorOccured}>
            <Label>
                <FieldWrapper>
                    <FieldElement
                        name={name}
                        value={value}
                        checked={checked}
                        onChange={onChangeFunc}
                    />
                    <FauxElement />
                </FieldWrapper>
                {label}
            </Label>
        </Wrapper>
    );
};

CheckboxOrRadio.propTypes = propTypes;
CheckboxOrRadio.defaultProps = defaultProps;

export default CheckboxOrRadio;
