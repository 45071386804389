import styled from 'styled-components';
import { gridPosable, gapable, remCalc, position, mq, colors } from '../../helpers/stylehelpers';
import infoIcon from '../../images/icons/info.svg';

const StepInfo = styled.div`
    line-height: 1.35;
    border-bottom: 1px solid ${colors.grayForms};
    padding: 0 0 1.2em ${remCalc(30)};
    position: relative;
    font-size: ${remCalc(16)};

    ${mq.medium`
        font-size: ${remCalc(18)};
    `};

    ${gridPosable()};
    ${gapable('l')};

    > p {
        margin-top: 0.5em;
    }

    &:before {
        content: '';
        ${position({ left: '0', top: '3px' })};
        flex-shrink: 0;
        display: block;
        background: url(${infoIcon}) no-repeat;
        background-size: 100%;
        height: ${remCalc(20)};
        width: ${remCalc(20)};
    }
`;

export default StepInfo;
