import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Headline, P } from '../elements/Typography';
import { Container } from '../layouts/Containers';

/** @type {styleComponent} Gestyltes Listenelement  */
const Wrapper = styled(Container)`
    &:last-child {
        margin-bottom: 0;
    }
`;

/** @type {styleComponent} Gestyltes Listenelement  */
const AttachmentItem = styled.span`
    word-wrap: break-word;
`;

/** @type {object} Props */
const propTypes = {
    headline: PropTypes.string.isRequired,
    values: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.object),
    ]),
};

/** @type {object} Default Props */
const defaultProps = {
    values: [],
};

/**
 * Wrapper Element zur Ausgabe der Anhänge
 */
const AttachmentWrapper = ({ headline, values }) => {
    const items =
        values.length &&
        values.map(value => {
            const el = value.name ? value.name : value;
            return (
                <AttachmentItem key={`${el}-${headline}`}>
                    {el}
                    <br />
                </AttachmentItem>
            );
        });

    return (
        <>
            {values.length > 0 && (
                <Wrapper gap="l">
                    <Headline level="h4" textColor="blue" gap="none">
                        {headline}
                    </Headline>
                    <P>{items}</P>
                </Wrapper>
            )}
        </>
    );
};

AttachmentWrapper.propTypes = propTypes;
AttachmentWrapper.defaultProps = defaultProps;

export default AttachmentWrapper;
