import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { colors, remCalc, mq } from '../../helpers/stylehelpers';

/**
 *  PropTypes
 * @type {Object}
 */
const propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    type: PropTypes.oneOf(['success', 'error']),
    title: PropTypes.string,
};

/**
 *  Default Props
 * @type {Object}
 */
const defaultProps = {
    children: '',
    type: 'error',
    title: 'Folgende Fehler sind aufgetreten:',
    className: null,
};

/**
 * Keyframes
 * @type {Keframes}
 */
const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

/**
 *  Wrapper
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
    background-color: ${({ type }) => (type === 'error' ? `${colors.alert}` : `${colors.success}`)};
    padding: ${remCalc(15, 15, 15, 15)};
    opacity: 0;
    animation: ${fadeIn} 0.4s 1;
    animation-fill-mode: forwards;

    ${mq.medium`
        padding: ${remCalc(15, 20, 15, 20)};
    `};
`;

/**
 *  Headline
 * @type {StyledComponent}
 */
const Headline = styled.strong`
    color: ${colors.white};
    display: block;
    font-size: ${remCalc(18)};
    margin-bottom: 0.25em;
    text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'inherit')};

    ${mq.medium`
        font-size: ${remCalc(22)};
    `};
`;

/**
 *  Liste
 * @type {StyledComponent}
 */
const List = styled.ul`
    margin: 0;
    padding-left: ${remCalc(20)};

    ${mq.medium`
        padding-left: ${remCalc(20)};
    `};
`;

/**
 *  Einzelne Items (Fehler)
 * @type {StyledComponent}
 */
const Item = styled.li`
    color: ${colors.white};
    font-size: ${remCalc(15)};
    margin-bottom: 0.35em;
    &:last-child {
        margin-bottom: 0;
    }
    ${mq.medium`
        font-size: ${remCalc(16)};
    `};
`;

/**
 *  Message (wenn nur 1 Nachricht)
 * @type {StyledComponent}
 */
const Message = styled.p`
    color: ${colors.white};
    line-height: 1.35;
    text-align: center;
    margin: 0;
`;

/**
 * MessageBox Komponente
 * @param {*} props
 */
const MessageBox = ({ children, className, type, title }) => {
    const content = Array.isArray(children) ? (
        <List>{children.map(message => <Item key={message}>{message}</Item>)}</List>
    ) : (
        <Message>{children}</Message>
    );

    return (
        <Wrapper className={className} type={type}>
            <Headline alignCenter={!Array.isArray(children)}>{title}</Headline>
            {content}
        </Wrapper>
    );
};

MessageBox.propTypes = propTypes;
MessageBox.defaultProps = defaultProps;

export default MessageBox;
