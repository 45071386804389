export default {
    club_name: process.env.NODE_ENV === 'development' ? 'FC Bayern' : '',
    club_division: process.env.NODE_ENV === 'development' ? 'Herrenfussball' : '',
    club_street: process.env.NODE_ENV === 'development' ? 'Säbener' : '',
    club_street_number: process.env.NODE_ENV === 'development' ? '5' : '',
    club_zipcode: process.env.NODE_ENV === 'development' ? '81535' : '',
    club_city: process.env.NODE_ENV === 'development' ? 'München' : '',
    club_phone: process.env.NODE_ENV === 'development' ? '089 / 123' : '',
    club_url: process.env.NODE_ENV === 'development' ? 'http://www.fcb.de' : '',
    club_membershipsize: process.env.NODE_ENV === 'development' ? 10 : '',
    club_youngmembershipsize: process.env.NODE_ENV === 'development' ? 20 : '',
    club_memberof: process.env.NODE_ENV === 'development' ? 'BSB Nord' : '',

    contact_firstname: process.env.NODE_ENV === 'development' ? 'Karl-Heinz' : '',
    contact_lastname: process.env.NODE_ENV === 'development' ? 'Rumenigge' : '',
    contact_role: process.env.NODE_ENV === 'development' ? 'Manager' : '',
    contact_street: process.env.NODE_ENV === 'development' ? 'Seeweg' : '',
    contact_street_number: process.env.NODE_ENV === 'development' ? '10' : '',
    contact_zipcode: process.env.NODE_ENV === 'development' ? '81535' : '',
    contact_city: process.env.NODE_ENV === 'development' ? 'Starnberg' : '',
    contact_phone: process.env.NODE_ENV === 'development' ? '089 / 123' : '',
    contact_email: process.env.NODE_ENV === 'development' ? 'khr@fcb.de' : '',

    campaign_title: process.env.NODE_ENV === 'development' ? 'Jugendleistungszentrum' : '',
    campaign_location: process.env.NODE_ENV === 'development' ? 'Säbener 10' : '',
    campaign_start: process.env.NODE_ENV === 'development' ? '2018-10-20' : '',
    campaign_end: process.env.NODE_ENV === 'development' ? '2018-10-25' : '',
    campaign_participants_number: process.env.NODE_ENV === 'development' ? '200' : '',
    campaign_participants_number_teenager: process.env.NODE_ENV === 'development' ? '100' : '',
    campaign_participants_age: process.env.NODE_ENV === 'development' ? '2 - 20' : '',
    campaign_description:
        process.env.NODE_ENV === 'development' ? 'Kurzbeschreibung der Aktion' : '',
    campaign_achievements: process.env.NODE_ENV === 'development' ? 'Spaß und Sport' : '',

    involvement_categories:
        process.env.NODE_ENV === 'development' ? ['Planung', 'Organisation', 'Ausführung'] : [],
    involvement_description:
        process.env.NODE_ENV === 'development' ? 'Kurzbeschreibung zur Beteiligung' : '',
    involvement_number: process.env.NODE_ENV === 'development' ? '20' : '',
    involvement_age: process.env.NODE_ENV === 'development' ? '10 - 12' : '',

    funding:
        process.env.NODE_ENV === 'development'
            ? ['Eigenfinanzierung', 'Spenden', 'Sponsoren', 'Finanzierung durch Verein / Abt.']
            : [],
    funding_other: process.env.NODE_ENV === 'development' ? 'Details zur Finanzierung' : '',

    attachments_photos: [],
    attachments_descriptions: [],
    attachments_documentations: [],
    attachments_videos: [],
    attachments_articles: [],
    attachments_others: [],

    terms: false,
    privacy: false,
};
