import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    propTypes as inputPropTypes,
    defaultProps as inputDefaultProps,
    Wrapper,
    InputField,
    Label,
} from './Input';

const propTypes = {
    ...inputPropTypes,
    maxFieldAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const defaultProps = {
    ...inputDefaultProps,
    maxFieldAmount: 10,
};

const StyledField = styled(InputField)``;

/**
 * MultiInput
 */
class MultiInput extends React.Component {
    state = {
        currentFieldAmount: 1,
        fieldsTouched: [],
    };

    /**
     * On Mount...
     */
    componentDidMount() {
        const {
            field: { value: fieldValue },
            maxFieldAmount,
        } = this.props;

        if (fieldValue && fieldValue.length) {
            const fieldsTouched = fieldValue.map((value, index) => index);
            this.setState({
                currentFieldAmount:
                    fieldValue.length + 1 <= maxFieldAmount
                        ? fieldValue.length + 1
                        : fieldValue.length,
                fieldsTouched,
            });
        }
    }

    /**
     * Blur Handler
     */
    onBlur = evt => {
        const index = evt.currentTarget.dataset.fieldIndex;
        const { value } = evt.currentTarget;
        const {
            // maxFieldAmount,
            form,
            name,
            field: { value: fieldValue },
        } = this.props;
        const newFieldValue = fieldValue;
        const { fieldsTouched } = this.state;

        if (value === '') {
            newFieldValue.splice(index, 1);
            fieldsTouched.splice(index, 1);
            form.setFieldTouched(name, true);
            form.setFieldValue(name, newFieldValue);

            this.setState(prevState => ({
                currentFieldAmount:
                    prevState.currentFieldAmount - 1 >= 0 ? prevState.currentFieldAmount - 1 : 0,
                fieldsTouched,
            }));
        }
    };

    /**
     * Change Handler für die Input
     */
    onChange = evt => {
        const { value } = evt.currentTarget;
        const {
            maxFieldAmount,
            form,
            name,
            field: { value: fieldValue },
        } = this.props;
        const index = evt.currentTarget.dataset.fieldIndex;
        const { fieldsTouched, currentFieldAmount } = this.state;
        const newFieldValue = fieldValue;

        // Value in Position setzen
        newFieldValue[index] = value;

        if (
            value.length > 0 &&
            !fieldsTouched.includes(Number(index)) &&
            currentFieldAmount + 1 <= maxFieldAmount
        ) {
            fieldsTouched.push(Number(index));
            this.setState(prevState => ({
                currentFieldAmount: prevState.currentFieldAmount + 1,
                fieldsTouched,
            }));
        }

        form.setFieldTouched(name, true);
        return form.setFieldValue(name, newFieldValue);
    };

    /**
     * Render
     */
    render() {
        const {
            field: { value },
            required,
            label,
            gap,
            gridColStart,
            gridColEnd,
        } = this.props;

        const labelMessage = label && (
            <span>
                {label}
                {required && ` *`}
            </span>
        );

        const { currentFieldAmount } = this.state;

        /* eslint-disable react/no-array-index-key */
        const inputElements = [...Array(Number(currentFieldAmount)).fill(0)].map((foo, index) => (
            <StyledField
                key={`multifield-${index}`}
                gap="l"
                data-field-index={index}
                value={value[index] || ''}
                onChange={this.onChange}
                onBlur={this.onBlur}
            />
        ));
        /* eslint-enable react/no-array-index-key */

        return (
            <Wrapper gap={gap} gridColStart={gridColStart} gridColEnd={gridColEnd}>
                <Label>
                    {labelMessage}
                    {inputElements}
                </Label>
            </Wrapper>
        );
    }
}

MultiInput.propTypes = propTypes;
MultiInput.defaultProps = defaultProps;

export default MultiInput;
