import React from 'react';
import { Field } from 'formik';

import { Container } from '../layouts/Containers';
import { Headline, P } from '../elements/Typography';
import { Link } from '../elements/ButtonsAndLinks';

import AgeRange from './AgeRange';
import CheckboxOrRadio from './CheckboxOrRadio';
import Input from './Input';
import DateInput from './DateInput';
import MultiInput from './MultiInput';
import FileUpload from './FileUpload';
import FileAmountChecker from './FileAmountChecker';
import Summary from './Summary';

import FormElementGroup from './FormElementGroup';

import {
    requiredMin3Max32,
    requiredMin3Max64,
    requiredMin3Max96,
    requiredMin3Max128,
    requiredEmail,
    requiredDate,
    requiredArray,
    requiredNumeric,
    optionalNumeric,
    optionalUrl,
    optionalAgeRange,
    optionalMin3Max32,
    optionalMin3Max512,
    requiredAgeRange,
} from './validators';

import { isDateInputSupported } from '../../helpers/utils';

export default [
    {
        title: 'Der Verein',
        info:
            'Bitte geben Sie hier Daten rund um Ihren Verein bzw. Ihre Abteilung an (Vereinsname, Abteilung, Vereinssitz, Adresse, Mitgliederzahl, Zugehörigkeit zu einem der drei Sportbünde) Bitte geben Sie die geforderten Daten vollständig an.',
        fields: (
            <>
                <Field
                    label="Vereinsname"
                    type="text"
                    name="club_name"
                    validate={requiredMin3Max96}
                    required
                    component={Input}
                    maxLength="96"
                />
                <Field
                    label="Abteilung"
                    type="text"
                    name="club_division"
                    validate={requiredMin3Max128}
                    required
                    component={Input}
                    maxLength="128"
                />
                <Field
                    label="Straße"
                    type="text"
                    name="club_street"
                    validate={requiredMin3Max64}
                    required
                    component={Input}
                    maxLength="64"
                />
                <Field
                    label="Hausnummer"
                    type="text"
                    name="club_street_number"
                    required
                    component={Input}
                    minLength="1"
                    maxLength="8"
                />
                <Field
                    label="PLZ"
                    type="number"
                    name="club_zipcode"
                    validate={requiredNumeric}
                    required
                    component={Input}
                    minLength="5"
                    maxLength="5"
                />
                <Field
                    label="Ort"
                    type="text"
                    name="club_city"
                    validate={requiredMin3Max64}
                    required
                    component={Input}
                    maxLength="64"
                />
                <Field
                    label="Telefon"
                    type="tel"
                    name="club_phone"
                    component={Input}
                    validate={optionalMin3Max32}
                    maxLength="32"
                />
                <Field
                    label="Homepage"
                    type="text"
                    name="club_url"
                    component={Input}
                    validate={optionalUrl}
                />
                <Field
                    label="Mitgliederzahl"
                    type="number"
                    name="club_membershipsize"
                    validate={optionalNumeric}
                    component={Input}
                    min={0}
                />
                <Field
                    label="davon Jugendliche"
                    type="number"
                    name="club_youngmembershipsize"
                    validate={optionalNumeric}
                    component={Input}
                    min={0}
                />
                <FormElementGroup
                    label="Mitglied beim:*"
                    gridColStart="1"
                    gap={{
                        small: 'none',
                        medium: 's',
                        large: 'm',
                    }}
                >
                    <Field
                        label="BSB Freiburg"
                        type="radio"
                        value="BSB Freiburg"
                        name="club_memberof"
                        component={CheckboxOrRadio}
                        required
                        validate={requiredArray}
                    />
                    <Field
                        label="BSB Nord"
                        type="radio"
                        value="BSB Nord"
                        name="club_memberof"
                        component={CheckboxOrRadio}
                        required
                        validate={requiredArray}
                    />
                    <Field
                        label="WLSB"
                        type="radio"
                        value="WLSB"
                        name="club_memberof"
                        component={CheckboxOrRadio}
                        required
                        validate={requiredArray}
                        gap="none"
                    />
                </FormElementGroup>
            </>
        ),
    },
    {
        title: 'Ansprechpartner',
        info:
            'Bitte geben Sie hier Daten zur Ansprechpartnerin/zum Ansprechpartner der Bewerbung an, damit wir Sie bei Rückfragen kontaktieren und über das Abschneiden beim Wettbewerb informieren können. Bitte geben Sie die geforderten Daten vollständig an.',
        fields: (
            <>
                <Field
                    label="Vorname"
                    type="text"
                    name="contact_firstname"
                    validate={requiredMin3Max32}
                    required
                    component={Input}
                    maxLength="32"
                />
                <Field
                    label="Name"
                    type="text"
                    name="contact_lastname"
                    validate={requiredMin3Max32}
                    required
                    component={Input}
                    maxLength="32"
                />
                <Field
                    label="Funktion"
                    type="text"
                    name="contact_role"
                    validate={requiredMin3Max64}
                    required
                    component={Input}
                    maxLength="64"
                />
                <Field
                    label="Straße"
                    type="text"
                    name="contact_street"
                    validate={requiredMin3Max64}
                    required
                    component={Input}
                    maxLength="64"
                />
                <Field
                    label="Hausnummer"
                    type="text"
                    name="contact_street_number"
                    required
                    component={Input}
                    maxLength="8"
                />
                <Field
                    label="PLZ"
                    type="number"
                    name="contact_zipcode"
                    validate={requiredNumeric}
                    required
                    component={Input}
                    minLength="5"
                    maxLength="5"
                />
                <Field
                    label="Ort"
                    type="text"
                    name="contact_city"
                    validate={requiredMin3Max64}
                    required
                    component={Input}
                    maxLength="64"
                />
                <Field
                    label="Telefon"
                    type="tel"
                    name="contact_phone"
                    validate={requiredMin3Max32}
                    required
                    component={Input}
                    maxLength="32"
                />
                <Field
                    label="E-Mail"
                    type="email"
                    name="contact_email"
                    validate={requiredEmail}
                    required
                    component={Input}
                />
            </>
        ),
    },
    {
        title: 'Die Aktion',
        info:
            'Hier können Sie die Aktion in kurzen Worten beschreiben. Damit sich die Jury bereits ein Bild von der Aktion machen kann, erläutern Sie in 1-2 Sätzen die wichtigsten Aussagen zu Ihrer Aktion (Max 500 Zeichen). Dies dient der Jury als Wegweiser in der Evaluation der Anlagen und der Beurteilung insgesamt.' +
            '<p>Für eine ausführliche Beschreibung der Aktion können Sie in Schritt 6 diverse Anlagen hochladen.</p>',
        fields: (
            <>
                <Field
                    label="Titel der Aktion"
                    type="text"
                    name="campaign_title"
                    validate={requiredMin3Max128}
                    required
                    gridColStart="1"
                    component={Input}
                />
                <Field
                    label="Zielsetzung der Aktion"
                    type="text"
                    name="campaign_achievements"
                    validate={requiredMin3Max128}
                    required
                    gridColStart="1"
                    component={Input}
                />
                <Container gridColStart="1" grid cols="2">
                    <Field
                        label={`Startdatum der Aktion${
                            !isDateInputSupported() ? ' (JJJJ-MM-TT)' : ''
                        }`}
                        type="formated-date"
                        name="campaign_start"
                        placeholder={!isDateInputSupported() ? 'JJJJ-MM-TT' : ''}
                        required
                        validate={requiredDate}
                        component={DateInput}
                    />
                    <Field
                        label={`Enddatum der Aktion${
                            !isDateInputSupported() ? ' (JJJJ-MM-TT)' : ''
                        }`}
                        type="formated-date"
                        name="campaign_end"
                        placeholder={!isDateInputSupported() ? 'JJJJ-MM-TT' : ''}
                        required
                        validate={requiredDate}
                        component={DateInput}
                    />
                </Container>
                <Field
                    label="Ort der Aktion"
                    type="text"
                    name="campaign_location"
                    validate={requiredMin3Max128}
                    required
                    gridColStart="1"
                    component={Input}
                />
                <Container
                    size="l"
                    gap={{
                        small: 'l',
                        medium: 's',
                        large: 'm',
                    }}
                    grid
                    cols="3"
                    gridColStart="1"
                >
                    <Field
                        label="Anzahl der Teilnehmer"
                        type="number"
                        name="campaign_participants_number"
                        required
                        component={Input}
                        validate={requiredNumeric}
                        min={0}
                    />
                    <Field
                        label="Davon Anzahl der Jugendlichen"
                        type="number"
                        name="campaign_participants_number_teenager"
                        required
                        component={Input}
                        validate={requiredNumeric}
                        min={0}
                    />
                    <Field
                        label="Altersspanne der Teilnehmer"
                        name="campaign_participants_age"
                        component={AgeRange}
                        required
                        validate={requiredAgeRange}
                    />
                </Container>
                <FormElementGroup label="Durchführung der Maßnahme" gridColStart="1" gap="l">
                    <Field
                        label="einmalig"
                        type="radio"
                        name="type_of_event"
                        value="einmalig"
                        component={CheckboxOrRadio}
                    />
                    <Field
                        label="regelmäßig"
                        type="radio"
                        name="type_of_event"
                        value="regelmäßig"
                        component={CheckboxOrRadio}
                    />
                    <Field
                        label="langfristig/dauerhaft"
                        type="radio"
                        name="type_of_event"
                        value="langfristig/dauerhaft"
                        component={CheckboxOrRadio}
                    />
                    <Field
                        label="coronabedingt"
                        type="radio"
                        name="type_of_event"
                        value="coronabedingt"
                        component={CheckboxOrRadio}
                    />
                </FormElementGroup>
                <Field
                    type="textarea"
                    name="campaign_description"
                    gridColStart="1"
                    label="Kurzbeschreibung der Aktion (ausführliche Beschreibung als Anhang)"
                    component={Input}
                    validate={optionalMin3Max512}
                    maxLength="512"
                />
            </>
        ),
    },
    {
        title: 'Beteiligung der Jugendlichen',
        info:
            'Hier würden wir insbesondere gerne erfahren, ob und mit welchem Aufgaben die Jugendlichen Ihres Vereins/Ihrer Abteilung in die Aktion eingebunden wurden (max. 500 Zeichen). ',
        fields: (
            <>
                <FormElementGroup
                    label="Die Jugendlichen waren aktiv bei der Aktion mit folgenden Aufgaben eingebunden:"
                    gridColStart="1"
                    gap="none"
                >
                    <Field
                        label="Projektleitung"
                        type="checkbox"
                        name="involvement_categories"
                        value="Planung"
                        component={CheckboxOrRadio}
                    />
                    <Field
                        label="Organisation"
                        type="checkbox"
                        name="involvement_categories"
                        value="Organisation"
                        component={CheckboxOrRadio}
                    />
                    <Field
                        label="Durchführung"
                        type="checkbox"
                        name="involvement_categories"
                        value="Durchführung"
                        component={CheckboxOrRadio}
                    />
                </FormElementGroup>
                <Field
                    type="textarea"
                    name="involvement_description"
                    gridColStart="1"
                    label="Wie waren die Jugendlichen eingebunden?"
                    validate={optionalMin3Max512}
                    component={Input}
                    maxLength="512"
                />
                <Container
                    size="l"
                    gap={{
                        small: 'l',
                        medium: 's',
                        large: 'm',
                    }}
                    grid
                    cols={2}
                    gridColStart="1"
                >
                    <Field
                        label="Anzahl der mitwirkenden Jugendlichen"
                        type="number"
                        name="involvement_number"
                        component={Input}
                        min={0}
                    />
                    <Field
                        label="Altersspanne"
                        name="involvement_age"
                        component={AgeRange}
                        validate={optionalAgeRange}
                    />
                </Container>
            </>
        ),
    },
    {
        title: 'Finanzierung der Maßnahmen',
        info:
            'Kreuzen Sie hier bitte an, wie die Aktion finanziert wurde, bzw. beschreiben Sie die Art der Finanzierung (max. 500 Zeichen).',
        fields: (
            <>
                <Field
                    label="Eigenfinanzierung durch Teilnehmer"
                    type="checkbox"
                    name="funding"
                    value="Eigenfinanzierung durch Teilnehmer"
                    validate={requiredArray}
                    required
                    component={CheckboxOrRadio}
                />
                <Field
                    label="Eigenfinanzierung durch Verein/Abteilung"
                    type="checkbox"
                    name="funding"
                    value="Eigenfinanzierung durch Verein/Abteilung"
                    validate={requiredArray}
                    required
                    component={CheckboxOrRadio}
                />
                <Field
                    label="Spenden"
                    type="checkbox"
                    name="funding"
                    value="Spenden"
                    validate={requiredArray}
                    required
                    component={CheckboxOrRadio}
                />
                <Field
                    label="Sponsoren"
                    type="checkbox"
                    name="funding"
                    value="Sponsoren"
                    validate={requiredArray}
                    required
                    component={CheckboxOrRadio}
                />
                <Field
                    label="Sonstiges"
                    type="checkbox"
                    name="funding"
                    value="Sonstiges"
                    validate={requiredArray}
                    required
                    component={CheckboxOrRadio}
                />
                <Field
                    label="Keine Kosten"
                    type="checkbox"
                    name="funding"
                    value="Keine Kosten"
                    validate={requiredArray}
                    required
                    component={CheckboxOrRadio}
                />
                <Field
                    label="Details für Finanzierung"
                    type="textarea"
                    name="funding_other"
                    maxLength="512"
                    gridColStart="1"
                    validate={optionalMin3Max512}
                    component={Input}
                />
            </>
        ),
    },
    {
        title: 'Anlagen',
        info:
            'Hier können Sie Ihre Aktion ausführlich beschreiben und der Jury darlegen, warum Ihre Aktion einen Preis bekommen sollte.' +
            '<p>Sie können dazu Projektbeschreibungen, Präsentationen und eine Auswahl an Presseartikeln oder anderen Medienberichten einreichen. Ergänzen Sie Ihre Bewerbung durch Bilder zur Aktion. Zudem haben Sie die Möglichkeit, <strong>bis zu zehn Videos zu verlinken.</strong></p>' +
            '<p><strong>Zulässig Links auf YouTube- und Vimeo</strong> sowie Verlinkungen zu Videos, die auf öffentlich zugänglichen Plattformen (wie zum Beispiel Ihrer Vereins-Website) hochgeladen sind. Verlinkungen von Facebook-Videos sind nicht möglich.</p>' +
            '<p>Sie können insgesamt bis <strong>maximal 20 Dateien</strong> hochladen, die einzelnen Dateien dürfen dabei eine <strong>Größe von 10 MB</strong> nicht überschreiten.<br/>Die Dateinamen dürfen nur aus Buchstaben von <strong>a bis z, Ziffern, dem "-" und "_" sowie einem "." bestehen.</strong></p>',
        fields: ({ maxAttachmentsAmount, currentAttachmentsAmount, onAttachmentsChange }) => (
            <>
                <Field
                    component={FileAmountChecker}
                    maxAttachmentsAmount={maxAttachmentsAmount}
                    currentAttachmentsAmount={currentAttachmentsAmount}
                />
                <Field
                    label="Projektbeschreibung (PDF)"
                    name="attachments_descriptions"
                    component={FileUpload}
                    accept="application/pdf"
                    maxSize={10 * 1000 * 1000}
                    maxAttachmentsAmount={maxAttachmentsAmount}
                    currentAttachmentsAmount={currentAttachmentsAmount}
                    onAttachmentsChange={onAttachmentsChange}
                />
                <Field
                    label="Projektdokumentation (PDF)"
                    name="attachments_documentations"
                    component={FileUpload}
                    accept="application/pdf"
                    maxSize={10 * 1000 * 1000}
                    maxAttachmentsAmount={maxAttachmentsAmount}
                    currentAttachmentsAmount={currentAttachmentsAmount}
                    onAttachmentsChange={onAttachmentsChange}
                />
                <Field
                    label="Fotos (JPG, PNG, GIF)"
                    name="attachments_photos"
                    component={FileUpload}
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                    maxSize={10 * 1000 * 1000}
                    maxAttachmentsAmount={maxAttachmentsAmount}
                    currentAttachmentsAmount={currentAttachmentsAmount}
                    onAttachmentsChange={onAttachmentsChange}
                />
                <Field
                    label="Presseberichte (PDF)"
                    name="attachments_articles"
                    component={FileUpload}
                    accept="application/pdf"
                    maxSize={10 * 1000 * 1000}
                    maxAttachmentsAmount={maxAttachmentsAmount}
                    currentAttachmentsAmount={currentAttachmentsAmount}
                    onAttachmentsChange={onAttachmentsChange}
                />
                <Field
                    label="Sonstiges (PDF, JPG, PNG, GIF)"
                    name="attachments_others"
                    component={FileUpload}
                    accept="application/pdf,image/jpg,image/jpeg,image/png,image/gif"
                    maxSize={10 * 1000 * 1000}
                    maxAttachmentsAmount={maxAttachmentsAmount}
                    currentAttachmentsAmount={currentAttachmentsAmount}
                    onAttachmentsChange={onAttachmentsChange}
                />
                <Field
                    label="Videos (bis zu 10 Stück)"
                    type="textarea"
                    name="attachments_videos"
                    gridColStart="1"
                    maxFieldAmount="10"
                    component={MultiInput}
                />
            </>
        ),
    },
    {
        title: 'Zusammenfassung',
        info:
            'Hier finden Sie eine Übersicht über die von Ihnen eingegeben Daten, bevor Sie die Bewerbung abschicken. Änderungen können Sie über das Feld „vorheriger Schritt“ vornehmen. Nach dem Abschicken Ihrer Bewerbung sind allerdings keine Änderungen mehr möglich.' +
            '<p>Der Schutz Ihrer Daten ist uns wichtig. Daher ist es erforderlich, dass Sie der Datenschutzbestimmung sowie den Teilnahmebedingungen zuzustimmen.</p>' +
            '<p>Sie erhalten nun eine Bestätigungs-E-Mail an die von Ihnen angegebene E-Mail-Adresse.</p>' +
            '<p><strong>Wir wünschen Ihnen viel Erfolg bei der Teilnahme am Lotto Sportjugend-Förderpreis 2020!</strong></p>',
        fields: ({ setCurrentStep, showEditButtons }) => (
            <>
                <Field
                    key="summary"
                    component={Summary}
                    setCurrentStep={setCurrentStep}
                    showEditButtons={showEditButtons}
                />

                <Container gridColStart="1" gap="m">
                    <Headline level="h3" textColor="blue">
                        Teilnahmebedingungen und Datenschutz
                    </Headline>
                    <P gap="l">
                        Um ihre Bewerbung einreichen zu können, müssen Sie unseren
                        Teilnahmebedingungen und den Datenschutzbestimmungen zustimmen.
                    </P>
                    <Field
                        label={
                            <span>
                                Ja, ich habe die{' '}
                                <Link to="/teilnahmebedingungen" target="_blank">
                                    Teilnahmebedingungen
                                </Link>{' '}
                                gelesen und akzeptiere diese
                            </span>
                        }
                        type="checkbox"
                        name="terms"
                        gap="l"
                        value
                        validate={value => !value && 'Bitte Feld ausfüllen'}
                        component={CheckboxOrRadio}
                    />
                    <Field
                        label={
                            <span>
                                Ja, ich habe die{' '}
                                <Link to="/datenschutz" target="_blank">
                                    Datenschutzbestimmungen
                                </Link>{' '}
                                gelesen und akzeptiere diese
                            </span>
                        }
                        type="checkbox"
                        name="privacy"
                        value
                        validate={value => !value && 'Bitte Feld ausfüllen'}
                        component={CheckboxOrRadio}
                    />
                </Container>
            </>
        ),
    },
];
