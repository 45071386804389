import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { gapable, colors, remCalc } from '../../helpers/stylehelpers';

const propTypes = {
    maxAttachmentsAmount: PropTypes.number.isRequired,
    currentAttachmentsAmount: PropTypes.number.isRequired,
};

/** @type {object} Style für Item innerhalb der Dropzone */
const Wrapper = styled.div`
    ${gapable({
        small: 'xl',
        medium: 'l',
        large: 'l',
    })};

    background-color: ${({ showError }) => (showError ? `${colors.alert};` : `${colors.success}`)};
    border-radius: ${remCalc(2)};
    color: ${colors.white};
    padding: 0.25em 0.5em;
    grid-column-start: 1;
    grid-column-end: -1;
    font-weight: bold;
    text-align: center;
`;

const FileAmountChecker = ({ maxAttachmentsAmount, currentAttachmentsAmount }) => (
    <Wrapper showError={maxAttachmentsAmount - currentAttachmentsAmount === 0}>
        {currentAttachmentsAmount} von maximal {maxAttachmentsAmount} ausgewählt
    </Wrapper>
);

FileAmountChecker.propTypes = propTypes;

export default FileAmountChecker;
