import sjfpPreistrager2018 from '../downloads/sjfp-preistrager-2018.pdf';
import sjfpAnalogesFormular2020 from '../downloads/sjfp-analoges-formular-mf-2020.pdf';
import sjfpFlyer2020 from '../downloads/sjfp-analoges-formular-2020.pdf';
import sjfpLeitfadenBewerbungsformular from '../downloads/sjfp-leitfaden-bewerbungsformular.pdf';
import sjfpProjektbeschreibungen2018 from '../downloads/sjfp-projektbeschreibungen-2018.pdf';
import sjfpPressemeldung from '../downloads/sjfp-pressemeldung.pdf';
import sjfp2020PressemeldungGewinner from '../downloads/sjfp-2020-pressemeldung-gewinner.pdf';
import sjfp2020ListePreistraeger from '../downloads/sjfp-2020-liste-preistraeger.pdf';
import sjfp2020Gewinner from '../downloads/sjfp-2020-gewinner.pdf';

export const downloadSjfpPreistrager2018 = sjfpPreistrager2018;
export const downloadSjfpAnalogesFormular2020 = sjfpAnalogesFormular2020;
export const downloadSjfpFlyer2020 = sjfpFlyer2020;
export const downloadsSjfpLeitfadenBewerbungsformular = sjfpLeitfadenBewerbungsformular;
export const downloadSjfpProjektbeschreibungen2018 = sjfpProjektbeschreibungen2018;
export const downloadSjfpPressemeldung = sjfpPressemeldung;
export const downloadSjfp2020PressemeldungGewinner = sjfp2020PressemeldungGewinner;
export const downloadSjfp2020Preistraeger = sjfp2020ListePreistraeger;
export const downloadSjfp2020Gewinner = sjfp2020Gewinner;
