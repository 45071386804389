import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { gapable, remCalc, gridPosable, colors, mq, position } from '../../helpers/stylehelpers';

/** @type {object} Props */
export const propTypes = {
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string.isRequired,
    field: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    form: PropTypes.shape({
        errors: PropTypes.object,
        touched: PropTypes.object,
    }).isRequired,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gridColStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gridColEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min: PropTypes.number,
};

/** @type {object} DefaultProps */
export const defaultProps = {
    placeholder: null,
    required: false,
    gap: null,
    gridColStart: null,
    gridColEnd: null,
    maxLength: null,
    min: null,
};

/** @type {styleComponent} Der Wrapper  */
export const Wrapper = styled.div`
    ${gapable({
        small: 'xxl',
        medium: 'l',
        large: 'l',
    })};
    ${gridPosable()};
    position: relative;
`;

/** @type {styleComponent} Fehlermeldung */
export const ErrorMessage = styled.span`
    background-color: ${colors.alert};
    ${position({ top: '100%', left: '0' })};
    font-size: ${remCalc(13)};
    font-weight: bold;
    color: ${colors.white};
    padding: 0.15em 0.5em;

    ${mq.medium`
        font-size: ${remCalc(14)};
        padding: 0.15em 0.5em;
    `};
`;

/** @type {styleComponent} Label um die ganze Komponente  */
export const Label = styled.label`
    font-weight: 700;
    display: block;
    > span {
        display: block;
        margin-bottom: 0.3em;
        font-size: ${remCalc(16)};

        ${mq.medium`
            font-size: ${remCalc(18)};
        `};
    }
`;

export const inputStyles = css`
    font-weight: normal;
    border: 0;
    border-radius: 0;
    ${({ showErrorBorder }) =>
        showErrorBorder
            ? `border: 1px solid ${colors.alert}`
            : `border: 1px solid transparent; border-bottom-color: ${colors.grayForms};`};
    background-color: ${colors.white};
    font-size: ${remCalc(18)};
    padding: 0.5em 0.5em;
    width: 100%;

    ${gapable()};

    ${mq.medium`
        font-size: ${remCalc(20)};
    `};

    &,
    &:valid,
    &:invalid {
        box-shadow: none;
        outline: 0;
    }
`;

/** @type {styleComponent} Input-Feld */
export const InputField = styled.input`
    ${inputStyles};
`;

/** @type {styleComponent} Das Textfeld  */
const Textarea = styled.textarea`
    ${({ showErrorBorder }) =>
        showErrorBorder
            ? `border: 1px solid ${colors.alert}`
            : `border: 1px solid ${colors.grayForms};`};
    background-color: ${colors.white};
    padding: 0.5em;
    min-height: ${remCalc(150)};
    width: 100%;
`;

/**
 * Input Komponente
 * ----------------------------------------------------------------------------
 * Stellt (Formik)-Input Elemente zur Verfügung
 * @param {string} props.name Name des Feldes
 * @param {string} props.field Formik Field
 * @param {object} props.error Fehler aus dem Formik-Formular
 * @param {object} props.touched Angefasste Felder aus Formik-Formular
 * @param {string} props.className Optional: Klassenname
 * @param {string} props.type Typ des Inputs
 * @param {string} props.placeholder Optional: Placeholder
 * @param {string} props.required Optional: Gibt an, ob Feld Pflichtfeld ist
 * @param {string} props.label Label des Elements
 * @param {string} props.gap Optional: Label des Elements
 * @param {string} props.gridColStart Optional: Mögliche Startposition im Grid
 * @param {string} props.gridColEnd Optional: Mögiche Endposition im Grid
 *
 * @example <Input type="text" name="foo" placeholder="bar"/>
 */
const Input = ({
    field: { name },
    field,
    form: { errors, touched },
    type,
    placeholder,
    required,
    label,
    gap,
    gridColStart,
    gridColEnd,
    maxLength,
    min,
}) => {
    // ErrorMessage holen, wenn Feld in errors aufgeführt wird
    const errorOccured = errors[name] && touched[name];
    const errorMessage = errorOccured && <ErrorMessage>{errors[name]}</ErrorMessage>;
    const labelMessage = label && (
        <span>
            {label}
            {required && ` *`}
        </span>
    );

    return (
        <Wrapper gap={gap} gridColStart={gridColStart} gridColEnd={gridColEnd}>
            <Label>
                {labelMessage}
                {type !== 'textarea' ? (
                    <InputField
                        {...field}
                        type={type}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        showErrorBorder={errorOccured}
                        min={min}
                    />
                ) : (
                    <Textarea
                        {...field}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        showErrorBorder={errorOccured}
                    />
                )}
            </Label>
            {errorMessage}
        </Wrapper>
    );
};
/* eslint-enable jsx-a11y/label-has-for */

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
