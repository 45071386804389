import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabScrollButton from '@material-ui/core/Tabs/TabScrollButton';

import { Container } from '../layouts/Containers';
import { colors, remCalc, fontFamily, mq } from '../../helpers/stylehelpers';
import stepArrowIcon from '../../images/icons/step-arrow.svg';
import arrowLeftIcon from '../../images/icons/arrow-left.svg';
import arrowRightIcon from '../../images/icons/arrow-right.svg';

/**
 * PropTypes
 * @type {object}
 */
const propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    activeIndex: PropTypes.number.isRequired,
    highestStep: PropTypes.number.isRequired,
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]).isRequired,
    disabled: PropTypes.bool,
};

/**
 * Default Props
 * @type {object}
 */
const defaultProps = {
    className: null,
    disabled: false,
};

/**
 * Wrapper um die Komponente
 * @type {styledComponent}
 */
const Wrapper = styled.div`
    background-color: ${colors.blueMedium};
    display: flex;
    counter-reset: list;
    padding: 0 1em;
    height: ${remCalc(50)};

    [role='tablist'] {
        margin-bottom: ${remCalc(-18)} !important;
    }
`;

/**
 * Innerer Wrapper um die Komponente
 * @type {styledComponent}
 */
const Inner = styled(Container)`
    position: relative;
    height: ${remCalc(50)};
`;

/**
 * TabContainer
 * @type {styledComponent}
 */
const TabContainer = styled(Tabs)`
    align-items: center;
`;

/**
 * Pfeilchen links und rechts
 * @type {styledComponent}
 */
const TabArrow = styled(TabScrollButton)`
    && {
        color: ${colors.white};
        display: block;
        flex: 0 0 auto;
        cursor: pointer;
        border: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: ${remCalc(14, 23)};
        position: relative;
        pointer-events: none;
        opacity: 0.5;
        max-width: ${remCalc(80)};
        height: ${remCalc(50)};
        flex-shrink: 0;
        width: ${remCalc(30)};

        ${mq.medium`
            width: ${remCalc(40)};
        `};

        > svg,
        > span {
            display: none;
        }

        &[type='button'] {
            opacity: 1;
            pointer-events: all;
        }

        &:first-child {
            background-image: url(${arrowLeftIcon});
            background-position: left center;
        }

        &:last-child {
            background-image: url(${arrowRightIcon});
            background-position: right center;
        }
    }
`;

/**
 * TabItem
 * @type {styledComponent}
 */
const TabItem = styled(Tab)`
    && {
        font-family: ${fontFamily};
        text-transform: none;
        color: ${colors.white};
        display: flex;
        align-items: center;
        font-weight: bold;
        margin-right: 1rem;
        flex-shrink: 0;
        opacity: 0.45;
        overflow: visible;
        min-width: auto;
        max-width: none;
        transition: opacity 0.25s;

        &,
        span {
            font-size: ${remCalc(16)};
            white-space: nowrap;
            padding: 0;

            ${mq.medium`
                font-size: ${remCalc(18)};
            `};

            ${mq.large`
                font-size: ${remCalc(20)};
            `};
        }

        > span {
            width: auto;
        }

        &[aria-selected='true'] {
            opacity: 1;
        }

        &:before {
            counter-increment: list;
            content: counter(list);
            font-size: ${remCalc(30)};
            position: relative;
            margin-right: 0.35em;
        }

        &:after {
            ${mq.large`
                content: '';
                margin: 0 0 0 0.9em;
                background-image: url(${stepArrowIcon});
                position: relative;
                top: 1px;
                background-repeat: no-repeat;
                height: ${remCalc(22)};
                width: ${remCalc(41)};
            `};
        }

        &:last-child {
            margin-right: 0;
            &:after {
                display: none;
            }
        }
    }

    > img {
        margin-left: 0.85em;
    }
`;

/**
 * StepNavigtion -> Paddleband Navigation für die Formularschritte
 *
 * @param {string} props.items Einzelnen Navigationselemente
 * @param {string} props.className (Optional) Klassenname zum erweiterten Styling
 * @param {func} props.onChange Callback für Klick auf die Einzelnen Tabs
 * @param {number} props.activeIndex Aktiver Index
 * @param {number} props.highestStep Höchstmöglichst klickbarer Schritt
 * @param {node} props.ref Höchstmöglichst klickbarer Schritt
 *
 */
const StepNavigation = ({
    items,
    className,
    onChange,
    activeIndex,
    highestStep,
    innerRef,
    disabled,
}) => {
    const tabItems = items.map((item, index) => (
        <TabItem
            key={`tab-item-${item}`}
            label={item}
            disableRipple
            disabled={
                disabled ||
                (activeIndex === highestStep ? index > highestStep + 1 : index > highestStep)
            }
        />
    ));

    return (
        <Wrapper className={className} innerRef={innerRef}>
            <Inner size="l">
                <TabContainer
                    value={Number(activeIndex)}
                    onChange={onChange}
                    scrollable
                    scrollButtons="on"
                    TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
                    ScrollButtonComponent={TabArrow}
                >
                    {tabItems}
                </TabContainer>
            </Inner>
        </Wrapper>
    );
};

StepNavigation.propTypes = propTypes;
StepNavigation.defaultProps = defaultProps;

export default StepNavigation;
