import React, { Fragment } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import Sorteo from '@guplabs/sorteo-client';
import map from 'lodash.map';
import mapValues from 'lodash.mapvalues';
import compact from 'lodash.compact';

import { Container, Section } from '../layouts/Containers';
import { Button, Link } from '../elements/ButtonsAndLinks';
import { log, isStorageAvailable } from '../../helpers/utils';
import track from '../../helpers/etracker';

import steps from './steps';
import initialValues from './initialValues';
import StepNavigation from './StepNavigation';
import StepInfo from './StepInfo';
import Loader from './Loader';
import MessageBox from '../elements/MessageBox';
import ProgressBar from '../elements/ProgressBar';
import { Headline, P } from '../elements/Typography';
import RevealBox from '../elements/RevealBox';

const StyledLoader = styled(Loader)`
    text-align: center;
    max-width: 60px;
`;

const sessionTrackingId = Math.random()
    .toString(36)
    .substr(2, 9);

const StoreMessage = styled.p`
    color: red;
    text-align: center;
    margin-bottom: 30px;
`;

/**
 * Registration Form
 */
class RegistrationForm extends React.Component {
    /**
     * Key für LocalStorage
     * @static
     */
    static STORAGE_KEY = 'formikValues';

    /**
     * Keys der Felder, die Files enthalten
     * @type {Array}
     */
    static attachmentFields = [
        'attachments_photos',
        'attachments_descriptions',
        'attachments_documentations',
        'attachments_articles',
        'attachments_others',
    ];

    /**
     * DOM Ref auf den Submit-Button
     * @type {React.RefObject}
     */
    submitButtonRef = React.createRef();

    /**
     * DOM Ref auf die Step Navigation
     * @type {React.RefObject}
     */
    stepNavigationRef = React.createRef();

    /**
     * Maximale Anzahl an Anhängen
     * @type {Number}
     */
    maxAttachmentsAmount = 20;

    /**
     * Sorteo-Client
     * @type {Sorteo}
     */
    sorteo = null;

    /**
     * State
     * @type {Object}
     */
    state = {
        currentStep: 0,
        highestStep: 0,
        currentAttachmentsAmount: 0,
        requestedStep: null,
        values:
            isStorageAvailable('localStorage') && localStorage.getItem(RegistrationForm.STORAGE_KEY)
                ? JSON.parse(localStorage.getItem(RegistrationForm.STORAGE_KEY))
                : initialValues,
        submitState: '', // uploading -> sending -> success / error
        sorteoErrors: null,
        uploadAmount: 0,
        uploadProgress: 0,
        showRestoreMessage: false,
    };

    /**
     * Lifecycle Hook bei Mount
     */
    componentDidMount() {
        this.sorteo = new Sorteo(process.env.GATSBY_SORTEO_URL);

        const { values } = this.state;

        if (values.club_name !== '') {
            this.setState({ showRestoreMessage: true });
        }
    }

    /**
     * onAttachmentChang
     */
    onAttachmentsChange = attachmentsAmount => {
        this.setState(prevState => ({
            currentAttachmentsAmount: prevState.currentAttachmentsAmount + attachmentsAmount,
        }));
    };

    /**
     * Setzt den aktuellen Step
     * @param {Number} value Index des zu setzenden Steps
     */
    setCurrentStep = value => {
        if (value >= 0 || value < steps.length - 1) {
            const { currentStep: lastStep, highestStep: prevHighestStep } = this.state;

            this.scrollTop();
            this.setState(prevState => ({
                currentStep: value,
                requestedStep: null,
                highestStep: prevState.highestStep > value ? prevState.highestStep : value,
            }));

            const { highestStep } = this.state;
            if (prevHighestStep < highestStep) {
                track(`Formular Step ${highestStep} ausgefüllt`);
            }
            track(
                `Formular | Step-Änderung | von ${lastStep + 1} zu ${value +
                    1} (höchster Schritt: ${highestStep + 1}) | #${sessionTrackingId}`
            );
        }

        this.setState({ showRestoreMessage: false });
    };

    /**
     * Inkrementiert den aktuellen Schritt
     */
    incrementStep = evt => {
        if (evt) {
            evt.currentTarget.blur();
        }
        const { currentStep } = this.state;
        this.setCurrentStep(currentStep + 1);
    };

    /**
     * Dekrementiert den aktuellen Schritt
     */
    decrementStep = evt => {
        evt.currentTarget.blur();
        const { currentStep } = this.state;
        this.setCurrentStep(currentStep - 1);
    };

    /**
     * Handling von Fehlern beim Abschicken des Formulars
     * @param {Object} form FormikBag Objekt
     * @returns {Function}
     */
    handleError = form => error => {
        form.setSubmitting(false);

        let sorteoErrors = error;

        if (error.data && error.data.error && error.data.error.data) {
            sorteoErrors = map(
                error.data.error.data,
                sorteoError => (Array.isArray(sorteoError) ? sorteoError.join(' | ') : sorteoError)
            );
        }

        this.setState({
            submitState: 'error',
            sorteoErrors,
        });

        log('Error sending data', error);
        track(
            `Formular | Fehler | Fehler beim Senden der Daten (${sorteoErrors}) | #${sessionTrackingId}`
        );
    };

    /**
     * Submit Handling
     */
    handleSubmit = (values, form) => {
        const { currentStep, requestedStep } = this.state;

        if (currentStep === steps.length - 1) {
            this.sorteo.contestId = process.env.GATSBY_CONTEST;

            this.sendDataToSorteo(values, form);
        } else {
            // Alle Felder wieder auf "nicht angefasst" stellen, sonst werden sie im nächsten Step sofort validiert
            form.setTouched(mapValues(values, () => false));

            if (isStorageAvailable('localStorage')) {
                // Anhänge nicht im localStorage ablegen
                const valuesWithoutAttachements = mapValues(values, (value, field) => {
                    if (RegistrationForm.attachmentFields.includes(field)) {
                        return [];
                    }
                    return value;
                });

                localStorage.setItem(
                    RegistrationForm.STORAGE_KEY,
                    JSON.stringify(valuesWithoutAttachements)
                );
            }

            this.setState(
                {
                    values,
                },
                () => {
                    form.setSubmitting(false);
                }
            );
            if (requestedStep !== null) {
                this.setCurrentStep(requestedStep);
            } else {
                this.incrementStep();
            }
        }
    };

    /**
     * Sendet die Daten an Sorteo
     * @param {Object} values Daten aus Formik
     * @param {Object} form Formik Formular-Actions
     */
    sendDataToSorteo(values, form) {
        const dataToSend = { ...values };
        const uploads = [];

        track(`Formular | Upload | Upload wird gestartet | #${sessionTrackingId}`);
        // Uploads starten
        RegistrationForm.attachmentFields.forEach(attachmentField => {
            if (values[attachmentField].length) {
                values[attachmentField].forEach(file => {
                    uploads.push(
                        this.sorteo.files
                            .upload(file, 'public')
                            .then(({ data }) => {
                                this.setState(prevState => ({
                                    uploadProgress: prevState.uploadProgress + 1,
                                }));

                                return {
                                    data,
                                    attachmentField,
                                };
                            })
                            .catch(error => {
                                // Dateinamen in die Fehlermeldung einbauen
                                let serverError = error;

                                if (error.data && error.data.error && error.data.error.data) {
                                    serverError = map(
                                        error.data.error.data,
                                        sorteoError =>
                                            Array.isArray(sorteoError)
                                                ? sorteoError.join(' | ')
                                                : sorteoError
                                    );
                                }

                                throw new Error(
                                    `${file.name} konnte nicht hochgeladen werden: ${serverError}`
                                );
                            })
                    );
                });
                dataToSend[attachmentField] = []; // Löschen der "File" Objekte
            }
        });

        dataToSend.attachments_videos = compact(values.attachments_videos); // leere Video-Felder entfernen

        this.setState({
            submitState: 'uploading',
            uploadAmount: uploads.length,
            uploadProgress: 0,
        });

        Promise.all(uploads)
            .then(response => {
                // Nach dem Upload die Daten an Sorteo senden

                dataToSend.attachments = [];

                response.forEach(({ attachmentField, data }) => {
                    dataToSend[attachmentField].push(data.url); // URLs vom Server in die Daten legen

                    const attachmentCategory = attachmentField.replace('attachments_', '');
                    if (dataToSend.attachments.indexOf(attachmentCategory) < 0) {
                        dataToSend.attachments.push(attachmentCategory);
                    }
                });

                if (dataToSend.attachments_videos && dataToSend.attachments_videos.length > 0) {
                    dataToSend.attachments.push('videos');
                }

                track(
                    `Formular | Upload von ${
                        uploads.length
                    } Dateien beendet | #${sessionTrackingId}`
                );
                log('Uploading finished', response);

                this.setState({
                    submitState: 'sending',
                });

                track(`Formular | Bewerbung | Eintragung wird gestartet | #${sessionTrackingId}`);
                log('Sending data', dataToSend);

                this.sorteo.entries
                    .store({
                        body: {
                            username: dataToSend.contact_email,
                            fields: dataToSend,
                        },
                    })
                    .then(() => {
                        // Alles war erfolgreich!

                        form.setSubmitting(false);
                        if (isStorageAvailable('localStorage')) {
                            localStorage.removeItem(RegistrationForm.STORAGE_KEY);
                        }

                        track(
                            `Formular | Bewerbung | Eintragung war erfolgreich | #${sessionTrackingId}`
                        );

                        this.setState({
                            submitState: 'success',
                        });
                        track('Online-Bewerbung abgeschickt');
                    })
                    .catch(this.handleError(form));
            })
            .catch(this.handleError(form));
    }

    /**
     * Scrollt zur StepNavi
     * @method scrollTop
     */
    scrollTop() {
        window.scroll({
            top: this.stepNavigationRef.current.getBoundingClientRect().top + window.pageYOffset,
            behavior: 'smooth',
        });
    }

    /**
     * Render
     */
    render() {
        const {
            currentStep,
            values,
            highestStep,
            currentAttachmentsAmount,
            submitState,
            sorteoErrors,
            uploadAmount,
            uploadProgress,
            showRestoreMessage,
        } = this.state;
        const stepInfo = steps[currentStep].info && (
            <StepInfo
                gridColStart="1"
                gap={{ small: 'xl', medium: 'm' }}
                dangerouslySetInnerHTML={{ __html: steps[currentStep].info }}
            />
        );

        let { fields } = steps[currentStep];

        // Methode für Anlagen bzw. Zusammenfassung mitgeben
        if (currentStep === 5) {
            fields = steps[currentStep].fields({
                maxAttachmentsAmount: this.maxAttachmentsAmount,
                currentAttachmentsAmount,
                onAttachmentsChange: this.onAttachmentsChange,
            });
        } else if (currentStep === 6) {
            fields = steps[currentStep].fields({
                setCurrentStep: this.setCurrentStep,
                showEditButtons: submitState === '',
            });
        }

        return (
            <Formik initialValues={values} onSubmit={this.handleSubmit}>
                {({ errors, submitForm, isSubmitting }) => (
                    <Fragment>
                        <Form noValidate>
                            <StepNavigation
                                items={steps.map(step => step.title)}
                                activeIndex={currentStep}
                                highestStep={highestStep}
                                innerRef={this.stepNavigationRef}
                                onChange={(evt, value) => {
                                    if (value < currentStep) {
                                        this.setCurrentStep(value);
                                    } else {
                                        this.setState({ requestedStep: value }, () => {
                                            submitForm();
                                        });
                                    }
                                }}
                                disabled={submitState !== ''}
                            />
                            <Section
                                bgColor="grayLight"
                                gap={{
                                    small: 'xl',
                                    medium: 'xl',
                                    large: 'xxl',
                                    xlarge: 'xxxl',
                                }}
                            >
                                {showRestoreMessage && (
                                    <StoreMessage>
                                        Ihre Eingaben werden automatisch im Browser gespeichert und
                                        werden automatisch wiederhergestellt, sobald Sie das
                                        Formular wieder aufrufen.<br />
                                        <b>
                                            Wir haben Ihre letzten Eingaben wieder für Sie
                                            hergestellt.
                                        </b>
                                    </StoreMessage>
                                )}

                                {!showRestoreMessage && (
                                    <StoreMessage>
                                        Ihre Eingaben werden automatisch im Browser gespeichert und
                                        werden automatisch wiederhergestellt, sobald Sie das
                                        Formular wieder aufrufen.
                                    </StoreMessage>
                                )}

                                <Container grid cols={3} gap="none" size="l">
                                    {stepInfo}
                                    {fields}
                                </Container>
                            </Section>
                            <Section bgColor="white" gap="m" disableVerticalPadding>
                                <Container textAlign="center" size="m">
                                    {submitState === '' && (
                                        <>
                                            <Button
                                                gap="m"
                                                disabled={currentStep === 0}
                                                type="button"
                                                onClick={this.decrementStep}
                                            >
                                                Vorheriger Schritt
                                            </Button>
                                            <Button
                                                gap="m"
                                                onClick={evt => evt.currentTarget.blur()}
                                                innerRef={this.submitButtonRef}
                                                content={typeof Object.keys(errors).length}
                                                disabled={isSubmitting}
                                                type="submit"
                                            >
                                                {currentStep === steps.length - 1
                                                    ? 'Teilnahme abschicken'
                                                    : 'Nächster Schritt'}
                                            </Button>
                                        </>
                                    )}
                                    {(submitState === 'uploading' || submitState === 'sending') &&
                                        uploadAmount > 0 && (
                                            <Container gap="xxl">
                                                <Headline level="h2">
                                                    Anlagen{' '}
                                                    {submitState === 'uploading'
                                                        ? 'werden'
                                                        : 'wurden'}{' '}
                                                    hochgeladen...
                                                </Headline>
                                                <ProgressBar
                                                    progress={
                                                        uploadAmount > 0
                                                            ? (uploadProgress / uploadAmount) * 100
                                                            : 0
                                                    }
                                                >
                                                    {uploadProgress} von {uploadAmount}
                                                </ProgressBar>
                                            </Container>
                                        )}
                                    {submitState === 'sending' && (
                                        <>
                                            <Headline level="h2">Speichere Daten...</Headline>
                                            <StyledLoader />
                                        </>
                                    )}
                                    {submitState === 'success' && (
                                        <MessageBox
                                            type="success"
                                            title="Vielen Dank für Ihre Teilnahme!"
                                        >
                                            Wir haben Ihre Daten erfolgreich gespeichert und
                                            benachrichtigen Sie sobald die Gewinner feststehen.
                                        </MessageBox>
                                    )}
                                    {submitState === 'error' && (
                                        <MessageBox type="error" title="Leider gab es einen Fehler">
                                            {Array.isArray(sorteoErrors)
                                                ? sorteoErrors
                                                : String(sorteoErrors)}
                                        </MessageBox>
                                    )}
                                </Container>
                                <Container textAlign="center" size="m">
                                    <RevealBox trigger="Probleme bei der Bewerbung?">
                                        <P>
                                            Sie erreichen uns Montag bis Freitag von 9-17 Uhr unter
                                            0711-81000112
                                            <br />
                                            oder per E-Mail unter{' '}
                                            <Link to="mailto:sjfp.support@lotto-bw.de" />.
                                        </P>
                                    </RevealBox>
                                </Container>
                            </Section>
                        </Form>
                    </Fragment>
                )}
            </Formik>
        );
    }
}

export default RegistrationForm;
