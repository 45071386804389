import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { gridPosable, gapable, mq } from '../../helpers/stylehelpers';

/**
 * Checkbox Komponente
 *
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {string} props.label Das angezeigte Label
 * @param {node} props.children Form Elemente
 *
 * @example
 *      <Checkbox name="club_memberof" label="BSB WLSB" value="bsb-wlsb" />
 */
const FormElementGroup = styled(({ className, label, children }) => (
    <div className={`formelementgroup ${className}`}>
        <div className="formelementgroup__label">{label}</div>
        <div className="formelementgroup__elements">{children}</div>
    </div>
))`
    ${gridPosable()};
    ${gapable()};

    ${mq.medium`
        display: flex;
    `};

    .formelementgroup__label {
        font-weight: bold;
        margin: 0 0 0.75em 0;
        ${mq.medium`
            margin: 0 1em 0 0;
        `};
    }

    .formelementgroup__elements {
        ${mq.medium`
            display: flex;
            flex-shrink: 0;
        `};

        > * {
            ${mq.medium`
                margin-right: 1em;
            `};
        }
    }
`;

FormElementGroup.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

FormElementGroup.defaultProps = {
    className: null,
};

export default FormElementGroup;
