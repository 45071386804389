import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors, gapable } from '../../helpers/stylehelpers';

const Wrapper = styled.div`
    ${gapable()};
`;

const Trigger = styled.button`
    background: none;
    border: none;
    border-bottom: 1px solid ${colors.primary};
    cursor: pointer;
    padding: 0.125em 0.5em;
    ${gapable('m')};

    &:active {
        transform: translateY(1px);
    }
`;

const Content = styled.div`
    opacity: ${({ revealed }) => (revealed ? 1 : 0)};
    transition: opacity 150ms;
`;

/**
 * Reaveal Box
 * ----------------------------------------------------------------------------
 * Eine Box deren Inhalt erst nach einem Klick auf deren Trigger angezeigt wird.
 *
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.trigger Der Trigger-Text zum Öffnen der Box
 * @param {object} props.children Der (initial versteckte) Inhalt
 *
 * @example
 *      <RevealBox trigger="Probleme bei der Bewerbung?">Lorem ipsum ...</RevealBox>
 */
class RevealBox extends Component {
    static propTypes = {
        gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        children: PropTypes.node.isRequired,
        trigger: PropTypes.string.isRequired,
    };

    static defaultProps = {
        gap: null,
    };

    state = {
        revealed: false,
    };

    /** Behandelt den Klick auf den Trigger */
    handleTriggerClick = () => {
        this.setState(prevState => ({ revealed: !prevState.revealed }));
    };

    /** Rendert die Komponente */
    render() {
        const { revealed } = this.state;
        const { children, trigger, gap } = this.props;

        return (
            <Wrapper gap={gap}>
                <Trigger type="button" onClick={this.handleTriggerClick}>
                    {trigger}
                </Trigger>
                <Content revealed={revealed}>{children}</Content>
            </Wrapper>
        );
    }
}

export default RevealBox;
