import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import uniqBy from 'lodash.uniqby';
import without from 'lodash.without';

import { gapable, gridPosable, mq, remCalc, position } from '../../helpers/stylehelpers';

import deleteIcon from '../../images/icons/trash.svg';

/** @type {styledComponent} Wrapper um die Komponente */
const Wrapper = styled.div`
    position: relative;
    ${gapable({
        small: 'xl',
        medium: 'l',
        large: 'l',
    })};
    ${gridPosable()};
`;

/** @type {styledComponent} Label um die Komponente */
const Label = styled.label`
    position: relative;
    font-weight: bold;
    display: block;
    margin-bottom: 0.3em;
    font-size: ${remCalc(16)};

    ${mq.medium`
        font-size: ${remCalc(18)};
    `};
`;

/** @type {styledComponent}  Innerer Dropzone Container */
const DropzoneInner = styled.div`
    display: flex;
    overflow-y: auto;
    height: 80%;
    width: 90%;
`;

/** @type {styledComponent} Wrapper um die Komponente */
const DropFiles = styled.small`
    display: block;
    margin: auto;
`;

/** @type {styledComponent} Löschen Button */
const DeleteButton = styled.button`
    cursor: pointer;
    border: 0;
    background-color: transparent;
    background-image: url(${deleteIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: ${remCalc(21)};
    ${position({ top: '-2px', right: '0' })};
    z-index: 10;
    width: ${remCalc(20)};

    ${mq.medium`
        top: 0;
    `};
`;

/** @type {object} Style für Item innerhalb der Dropzone */
const FileItem = styled.div`
    margin-bottom: 0.15em;
`;
/** @type {styledComponent} Lösch Button */
const ErrorMessage = styled.p`
    font-size: ${remCalc(15)};
    margin: 0.5em 0 0;
`;

/** @type {object} Style für Dropzone */
const dropzoneStyle = {
    backgroundColor: '#ffffff',
    position: 'relative',
    textAlign: 'center',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: '5em',
    borderWidth: '2px',
    borderColor: '#666666',
    borderStyle: 'dashed',
    borderRadius: '5px',
    width: '100%',
};

/** @type {object} Active-Style für Dropzone */
const dropzoneActiveStyle = {
    backgroundColor: '#7aba4e',
    borderColor: '#ffffff',
    color: '#ffffff',
};

/** @type {object} Active-Style für Dropzone */
const dropzoneRejectStyle = {
    backgroundColor: '#cc4b37',
    borderColor: '#ffffff',
    color: '#ffffff',
};

/**
 * Docs fehlen
 */
class FileUpload extends React.Component {
    /** @type {object} Props */
    static propTypes = {
        label: PropTypes.string.isRequired,
        gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.array.isRequired,
        }).isRequired,
        form: PropTypes.shape({
            errors: PropTypes.object,
            touched: PropTypes.object,
        }).isRequired,
        gridColStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        gridColEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        accept: PropTypes.string,
        maxSize: PropTypes.number,
        maxAttachmentsAmount: PropTypes.number.isRequired,
        currentAttachmentsAmount: PropTypes.number.isRequired,
        onAttachmentsChange: PropTypes.func.isRequired,
    };

    /** @type {object} DefaultProps */
    static defaultProps = {
        gap: null,
        gridColStart: null,
        gridColEnd: null,
        accept: null,
        maxSize: Infinity,
    };

    state = {
        rejectedFiles: [],
        showLoading: false,
    };

    /**
     * onDropHandler
     * @param {Array} acceptedFiles Akzeptierte Dateiene
     * @param {Array} rejectedFiles Abgewiesene Dateien
     */
    onDrop = (
        acceptedFiles,
        rejectedFiles,
        form,
        value,
        name,
        maxAttachmentsAmount,
        currentAttachmentsAmount,
        onAttachmentsChange
    ) => {
        const currentFiles = value;
        const newFilesAmount = acceptedFiles.length;
        let spliced = [];

        form.setFieldTouched(name, true);

        if (currentAttachmentsAmount + newFilesAmount > maxAttachmentsAmount) {
            const filesRemaining = currentAttachmentsAmount + newFilesAmount - maxAttachmentsAmount;
            spliced = acceptedFiles.splice(filesRemaining * -1);
        }

        // avoid memory leaks
        // @see https://github.com/react-dropzone/react-dropzone#word-of-caution-when-working-with-previews
        acceptedFiles.forEach(file => window.URL.revokeObjectURL(file.preview));

        const uniqAccepted = uniqBy(currentFiles.concat(acceptedFiles), 'name');
        form.setFieldValue(name, uniqAccepted);

        // Länge der "echten" neuen Dateien berechnent
        const realNewAttachments = without(uniqAccepted, ...currentFiles);
        onAttachmentsChange(realNewAttachments.length);

        this.setState({
            rejectedFiles: rejectedFiles.concat(spliced),
            showLoading: false,
        });
    };

    /**
     * Löscht Dateien aus der Zone
     * @param {MouseEvent} evt Klick-Event
     */
    deleteFiles = (evt, form, name, currentAttachmentsAmount, onAttachmentsChange) => {
        evt.preventDefault();
        onAttachmentsChange(-currentAttachmentsAmount);
        form.setFieldValue(name, []);
        this.setState({
            rejectedFiles: [],
        });
    };

    /**
     * Render
     */
    render() {
        const {
            field: { name, value },
            form,
            label,
            gap,
            gridColStart,
            gridColEnd,
            accept,
            maxSize,
            maxAttachmentsAmount,
            currentAttachmentsAmount,
            onAttachmentsChange,
        } = this.props;

        // Abgewiesene Dateien aus dem State
        const { rejectedFiles, showLoading } = this.state;

        return (
            <Wrapper gap={gap} gridColStart={gridColStart} gridColEnd={gridColEnd}>
                {value.length ? (
                    <DeleteButton
                        onClick={evt =>
                            this.deleteFiles(evt, form, name, value.length, onAttachmentsChange)
                        }
                        aria-label="Dateien löschen"
                    />
                ) : null}
                <>
                    <Label htmlFor={`fileinput-${name}`}>{label}</Label>
                    <Dropzone
                        style={dropzoneStyle}
                        activeStyle={dropzoneActiveStyle}
                        rejectStyle={dropzoneRejectStyle}
                        id={`fileinput-${name}`}
                        onDrop={(accepted, rejected) =>
                            this.onDrop(
                                accepted,
                                rejected,
                                form,
                                value,
                                name,
                                maxAttachmentsAmount,
                                currentAttachmentsAmount,
                                onAttachmentsChange
                            )
                        }
                        accept={accept}
                        maxSize={maxSize}
                    >
                        <DropzoneInner alignCenter={!value.length} showLoading={showLoading}>
                            <DropFiles>
                                {/* eslint-disable react/jsx-indent */}
                                {value.length > 0
                                    ? value.map(file => (
                                          <FileItem key={file.name}>{file.name}</FileItem>
                                      ))
                                    : `Dateien auf die Fläche ziehen oder klicken.`}
                                {/* eslint-enable react/jsx-indent */}
                            </DropFiles>
                        </DropzoneInner>
                    </Dropzone>
                    {rejectedFiles &&
                        rejectedFiles.length > 0 && (
                            <ErrorMessage>
                                <strong>
                                    Folgende Dateien sind entweder zu groß, haben eine falsche
                                    Endung oder Sie haben die maximale Anzahl der Uploads
                                    überschritten:
                                </strong>
                                <br />
                                {rejectedFiles.map(file => (
                                    <span key={`file-rejected-${file.name}`}>
                                        {file.name}
                                        <br />
                                    </span>
                                ))}
                            </ErrorMessage>
                        )}
                </>
            </Wrapper>
        );
    }
}

export default FileUpload;
