import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AttachmentWrapper from './AttachmentWrapper';
import { colors, remCalc, mq } from '../../helpers/stylehelpers';
import { Headline } from '../elements/Typography';
import { List, ListItem } from '../elements/Lists';
import { Button } from '../elements/ButtonsAndLinks';

/** @type {object} Props */
const propTypes = {
    form: PropTypes.shape({
        values: PropTypes.object,
    }).isRequired,
    setCurrentStep: PropTypes.func,
    showEditButtons: PropTypes.bool,
};

/** @type {object} defaultProps */
const defaultProps = {
    setCurrentStep: null,
    showEditButtons: true,
};

/** @type {styleComponent} Der Wrapper  */
const Wrapper = styled.div`
    grid-column-start: 1;
    grid-column-end: -1;
`;

/** @type {styleComponent} Zusammenfassungs-Block nach Schritt */
const FieldBlock = styled.div`
    border-bottom: 1px solid ${colors.grayForms};
    padding-bottom: 1.5em;
    margin-bottom: 1.5em;
    &:last-child {
        margin-bottom: 0.5em;
    }
`;

/** @type {styleComponent} Gestyltes Listenelement  */
const StyledListItem = styled(ListItem)`
    line-height: 1.35;
`;

/** @type {styleComponent} Editieren Button  */
const EditButton = styled(Button)`
    display: block;
    font-size: ${remCalc(16)};
    text-align: center;

    ${mq.mediumDown} {
        margin: 0.5em 0;
        max-width: none;
    }

    ${mq.medium`
        margin: 0;
        width: auto;
    `};
`;

/** @type {styleComponent} SummaryHeadline   */
const SummaryHeadline = styled(Headline)`
    display: flex;
    flex-direction: column;
    ${mq.medium`
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    `};
`;

/** @type {string} Text im Button   */
const editButtonText = 'Angaben ändern';

/**
 * Summary Komponente
 * ----------------------------------------------------------------------------
 * Zeigt am Ende des Formulars alle Daten an
 * @param {string} props.values Felder des Formulars
 * @param {string} props.validate Validierungs-Funktion für die beiden Checkboxes
 *
 */
const Summary = ({ form: { values }, setCurrentStep, showEditButtons }) => {
    const attachmentsPresent =
        values.attachments_photos.length > 0 ||
        values.attachments_descriptions.length > 0 ||
        values.attachments_documentations.length > 0 ||
        values.attachments_videos.length > 0 ||
        values.attachments_articles.length > 0 ||
        values.attachments_others.length > 0;

    return (
        <Wrapper>
            <SummaryHeadline level="h2" textAlign="left" gridColStart="1">
                Zusammenfassung
            </SummaryHeadline>
            <FieldBlock>
                <SummaryHeadline level="h3" textColor="blue">
                    Der Verein
                    {showEditButtons && (
                        <EditButton type="button" onClick={() => setCurrentStep(0)}>
                            {editButtonText}
                        </EditButton>
                    )}
                </SummaryHeadline>
                <List gap="m">
                    <StyledListItem type="plain">
                        <strong>Vereinsname:</strong> {values.club_name}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Abteilung:</strong> {values.club_division}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Straße, Hausnummer:</strong> {values.club_street}{' '}
                        {values.club_street_number}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>PLZ, Ort:</strong> {values.club_zipcode} {values.club_city}
                    </StyledListItem>
                    {values.club_phone && (
                        <StyledListItem type="plain">
                            <strong>Telefon:</strong> {values.club_phone}
                        </StyledListItem>
                    )}
                    {values.club_url && (
                        <StyledListItem type="plain">
                            <strong>Homepage:</strong> {values.club_url}
                        </StyledListItem>
                    )}
                    {values.club_membershipsize >= 0 && (
                        <StyledListItem type="plain">
                            <strong>Mitgliederzahl:</strong> {values.club_membershipsize}
                        </StyledListItem>
                    )}
                    {values.club_youngmembershipsize >= 0 && (
                        <StyledListItem type="plain">
                            <strong>davon Jugendliche:</strong> {values.club_youngmembershipsize}
                        </StyledListItem>
                    )}
                    {values.club_memberof && (
                        <StyledListItem type="plain">
                            <strong>Mitglied beim:</strong> {values.club_memberof}
                        </StyledListItem>
                    )}
                </List>
            </FieldBlock>
            <FieldBlock>
                <SummaryHeadline level="h3" textColor="blue">
                    Ansprechpartner
                    {showEditButtons && (
                        <EditButton type="button" onClick={() => setCurrentStep(1)}>
                            {editButtonText}
                        </EditButton>
                    )}
                </SummaryHeadline>
                <List gap="m">
                    <StyledListItem type="plain">
                        <strong>Vorname:</strong> {values.contact_firstname}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Nachname:</strong> {values.contact_lastname}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Funktion:</strong> {values.contact_role}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Straße, Hausnummer:</strong> {values.contact_street}{' '}
                        {values.contact_street_number}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>PLZ, Ort:</strong> {values.contact_zipcode} {values.contact_city}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Telefon:</strong> {values.contact_phone}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>E-Mail:</strong> {values.contact_email}
                    </StyledListItem>
                </List>
            </FieldBlock>
            <FieldBlock>
                <SummaryHeadline level="h3" textColor="blue">
                    Die Aktion
                    {showEditButtons && (
                        <EditButton type="button" onClick={() => setCurrentStep(2)}>
                            {editButtonText}
                        </EditButton>
                    )}
                </SummaryHeadline>
                <List gap="m">
                    <StyledListItem type="plain">
                        <strong>Titel der Aktion:</strong> {values.campaign_title}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Startdatum der Aktion:</strong> {values.campaign_start}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Enddatum der Aktion:</strong> {values.campaign_end}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Ort der Aktion:</strong> {values.campaign_location}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Anzahl der Teilnehmer:</strong>{' '}
                        {values.campaign_participants_number}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Davon Anzahl der Jugendlichen:</strong>{' '}
                        {values.campaign_participants_number_teenager}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Altersspanne der Teilnehmer:</strong>{' '}
                        {values.campaign_participants_age}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Durchführung der Maßnahme:</strong> <br />
                        {values.type_of_event}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Kurzbeschreibung der Aktion:</strong> <br />
                        {values.campaign_description}
                    </StyledListItem>
                </List>
            </FieldBlock>
            <FieldBlock>
                <SummaryHeadline level="h3" textColor="blue">
                    Beteiligung der Jugendlichen
                    {showEditButtons && (
                        <EditButton type="button" onClick={() => setCurrentStep(3)}>
                            {editButtonText}
                        </EditButton>
                    )}
                </SummaryHeadline>
                <List gap="m">
                    <StyledListItem type="plain">
                        <strong>
                            Die Jugendlichen waren aktiv bei der Aktion mit folgenden Aufgaben
                            eingebunden:
                        </strong>
                        <br />
                        {values.involvement_categories.map(
                            (category, index) =>
                                `${category}${
                                    index !== values.involvement_categories.length - 1 ? ', ' : ''
                                }`
                        )}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Kurzbeschreibung zur Einbindung der Jugendlichen:</strong> <br />
                        {values.involvement_description}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Anzahl der mitwirkenden Jugendlichen:</strong>{' '}
                        {values.involvement_number}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Altersspanne der mitwirkenden Jugendlichen:</strong>{' '}
                        {values.involvement_age}
                    </StyledListItem>
                </List>
            </FieldBlock>
            <FieldBlock>
                <SummaryHeadline level="h3" textColor="blue">
                    Finanzierung der Maßnahmen
                    {showEditButtons && (
                        <EditButton type="button" onClick={() => setCurrentStep(4)}>
                            {editButtonText}
                        </EditButton>
                    )}
                </SummaryHeadline>
                <List gap="m">
                    <StyledListItem type="plain">
                        <strong>Die Finanzierung der Maßnahmen erfolgte durch:</strong>
                        <br />
                        {values.funding.map(
                            (value, index) =>
                                `${value}${index !== values.funding.length - 1 ? ', ' : ''}`
                        )}
                    </StyledListItem>
                    <StyledListItem type="plain">
                        <strong>Details für sonstige Finanzierung:</strong> <br />
                        {values.funding_other}
                    </StyledListItem>
                </List>
            </FieldBlock>
            {attachmentsPresent && (
                <FieldBlock>
                    <SummaryHeadline level="h3" textColor="blue">
                        Anlagen
                        {showEditButtons && (
                            <EditButton type="button" onClick={() => setCurrentStep(5)}>
                                {editButtonText}
                            </EditButton>
                        )}
                    </SummaryHeadline>
                    <AttachmentWrapper
                        headline="Projektbeschreibung (PDF)"
                        values={values.attachments_descriptions}
                    />
                    <AttachmentWrapper
                        headline="Projektdokumentation (PDF)"
                        values={values.attachments_documentations}
                    />
                    <AttachmentWrapper
                        headline="Fotos (JPG, PNG, GIF)"
                        values={values.attachments_photos}
                    />
                    <AttachmentWrapper
                        headline="Presseberichte (PDF)"
                        values={values.attachments_articles}
                    />
                    <AttachmentWrapper
                        headline="Sonstiges (PDF, JPG, PNG, GIF)"
                        values={values.attachments_others}
                    />
                    <AttachmentWrapper headline="Videos" values={values.attachments_videos} />
                </FieldBlock>
            )}
        </Wrapper>
    );
};

Summary.propTypes = propTypes;
Summary.defaultProps = defaultProps;

export default Summary;
