import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { gapable, position, icons, mq, colors } from '../../helpers/stylehelpers';

/**
 * List Item
 * ----------------------------------------------------------------------------
 * Dieses Element bildet die List Items ab.
 *
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl') - Standard: 'l')
 * @param {string} props.type Optional: Der Typ des Items ("plain" = ohne Icon davor)
 *
 * @example
 *       <ListItem>Lorem ipsum</ListItem>
 */
export const ListItem = styled(({ className, children }) => (
    <li className={`list__item ${className}`}>{children}</li>
))`
    ${gapable('m')};

    &:last-child {
        margin-bottom: 0;
    }

    line-height: 1.35;

    ${({ type }) =>
        type !== 'plain'
            ? css`
                  padding-left: 1.5em;
                  position: relative;

                  &::before {
                      content: ${icons.listArrow};
                      display: block;
                      font-size: 0.9em;
                      ${position({ left: '0', top: '2px' })};
                  }
              `
            : ''};

    ${({ margin }) =>
        margin &&
        css`
            ${mq.medium`
                margin-right: 1em;
                margin-bottom: 0;
                &:last-child {
                   margin-right: 0;
                }
            `};
        `};

    ${({ hasBorder }) =>
        hasBorder &&
        `
            border-bottom: 1px solid ${colors.gray};
            padding-bottom: 1rem;
            &:last-child {
                border-bottom: 0;
            }
    `};
`;
ListItem.propTypes = {
    children: PropTypes.node,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
    hasBorder: PropTypes.bool,
};
ListItem.defaultProps = {
    children: null,
    gap: null,
    type: null,
    hasBorder: false,
};

/**
 * Liste
 * ----------------------------------------------------------------------------
 * Dieses Element bildet normale Listen ab.
 *
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl') - Standard: 'l'
 * @param {array} props.items Optional: Die Items der Liste (für einfache Listen
 * @param {bool} props.flex Optional: Flex-Darstellung ab Medium
 * @param {string} props.type Optional: Umschaltung zwischen OL und UL
 * @param {string} props.plain Optional: Mach alle Items Plain
 *
 * @example
 *      <List gap="m">
 *          <ListItem>Lorem ipsum</ListItem>
 *          <ListItem>Lorem punktum</ListItem>
 *      </List>
 *
 *      Oder:
 *
 *      <List items={[
 *          'Lorem Ipsum Item',
 *          'Lorem Ipsum Moep',
 *          'Lorem Ipsum Nocheins',
 *      ]} />
 *
 */
export const List = styled(({ className, items, children, type, flex }) => {
    const L = type === 'ol' ? styled.ol`` : styled.ul``;

    return (
        <L className={`list ${className}`}>
            {/* eslint-disable react/jsx-indent */}
            {items.length
                ? items.map(item => (
                      <ListItem key={item} margin={flex}>
                          {item}
                      </ListItem>
                  ))
                : children}
            {/* eslint-enable react/jsx-indent */}
        </L>
    );
})`
    ${({ type }) =>
        type === 'ol'
            ? ''
            : `
        list-style-type: none;
        list-style-position: inside;
        margin: 0;
        padding: 0;
    `};

    ${gapable()};

    ${({ flex }) => (flex ? mq.medium`display: flex;` : '')};
`;

List.propTypes = {
    children: PropTypes.node,
    flex: PropTypes.bool,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    items: PropTypes.arrayOf(PropTypes.node),
    type: PropTypes.string,
};
List.defaultProps = {
    children: null,
    flex: false,
    gap: null,
    items: [],
    type: null,
    plain: null,
};
