import React from 'react';
import styled from 'styled-components';
import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import { isDateInputSupported } from '../../helpers/utils';

import {
    propTypes as inputPropTypes,
    defaultProps as inputDefaultProps,
    Wrapper,
    Label,
    ErrorMessage,
    inputStyles,
} from './Input';

/** @type {object} Props */
const propTypes = {
    ...inputPropTypes,
    fieldToWatch: PropTypes.string,
    checkFunc: PropTypes.string,
};

/** @type {object} defaultProps */
const defaultProps = {
    ...inputDefaultProps,
    fieldToWatch: null,
    checkFunc: null,
};

/** @type {DOM} Wrapper für Props */
const WrappedCleave = ({ showErrorBorder, ...props }) => <Cleave {...props} />;
WrappedCleave.propTypes = { showErrorBorder: PropTypes.bool };
WrappedCleave.defaultProps = { showErrorBorder: null };

/** @type {styledComponent} Date-Input mit Cleave Formatierung */
const DateField = styled(WrappedCleave)`
    ${inputStyles};
`;

/** @type {styledComponent} Date-Input mit nativem Datepicker */
const NativeDateField = styled.input.attrs({ type: 'date' })`
    ${inputStyles};
`;

const DateInput = ({
    field: { name },
    field,
    form: { errors, touched },
    type,
    placeholder,
    required,
    label,
    gap,
    gridColStart,
    gridColEnd,
    maxLength,
}) => {
    // ErrorMessage holen, wenn Feld in errors aufgeführt wird
    const errorOccured = errors[name] && touched[name];
    const errorMessage = errorOccured && <ErrorMessage>{errors[name]}</ErrorMessage>;
    const labelMessage = label && (
        <span>
            {label}
            {required && ` *`}
        </span>
    );

    return (
        <Wrapper gap={gap} gridColStart={gridColStart} gridColEnd={gridColEnd}>
            <Label>
                {labelMessage}
                {isDateInputSupported() ? (
                    <NativeDateField {...field} showErrorBorder={errorOccured} />
                ) : (
                    <DateField
                        {...field}
                        type={type}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        showErrorBorder={errorOccured}
                        options={{
                            date: true,
                            datePattern: ['Y', 'm', 'd'],
                            delimiter: '-',
                        }}
                    />
                )}
            </Label>
            {errorMessage}
        </Wrapper>
    );
};

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default DateInput;
