// Utilities

/**
 * Loggt in die Browser-Konsole, wenn sie verfügbar ist
 * @param  {...any} args Argumente die geloggt werden sollen
 */
export function log(...args) {
    if (typeof window !== 'undefined' && window.console && window.console.log) {
        window.console.log(...args);
    }
}

/**
 * Prüft ob LocalStorage oder SessionStorage vorhanden ist und funktioniert
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
 * @param {String} type 'LocalStorage' oder 'SessionStorage'
 * @returns {Boolean}
 */
export function isStorageAvailable(type) {
    let storage;

    if (typeof window === 'undefined') {
        return false;
    }

    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0
        );
    }
}

let isSupportedCache = null;

/**
 * Prüft ob <input type="date"> möglich ist
 * @returns {Boolean}
 */
export function isDateInputSupported() {
    if (isSupportedCache !== null) {
        return isSupportedCache;
    }

    if (typeof document !== 'undefined') {
        try {
            const input = document.createElement('input');
            input.type = 'date';

            isSupportedCache = input.type === 'date';
        } catch (error) {
            isSupportedCache = false;
        }
        return isSupportedCache;
    }

    return false;
}
