import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

const propTypes = {
    children: PropTypes.node,
    progress: PropTypes.number.isRequired,
};

const defaultProps = {
    children: '',
};

const Progress = styled.div`
    background-color: #e9ecef;
    border-radius: 0.25rem;
    display: flex;
    font-size: 0.75rem;
    height: 1rem;
    overflow: hidden;
`;

const stripeAnimation = keyframes`
    0% {
        background-position: 1rem 0;
    }
    100% {
        background-position: 0 0;
    }
`;

const Bar = styled.div`
    animation: ${({ valueNow }) =>
        valueNow < 100 &&
        css`
            ${stripeAnimation} 1s linear infinite;
        `};
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width 0.6s ease;
    width: ${({ valueNow }) => `${valueNow === 0 ? 5 : valueNow}%`};
`;

const ProgressBar = ({ children, progress }) => (
    <Progress>
        <Bar
            role="progressbar"
            aria-valuenow={progress}
            valueNow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
        >
            {progress > 0 && children}
        </Bar>
    </Progress>
);

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
