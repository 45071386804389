import React from 'react';

import { phased } from '../helpers/phases';
import BaseLayout from '../components/layouts/BaseLayout';
import RegistrationForm from '../components/form/RegistrationForm';

import { Section } from '../components/layouts/Containers';
import { Headline, P } from '../components/elements/Typography';
import { Link } from '../components/elements/ButtonsAndLinks';

import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';

import { downloadsSjfpLeitfadenBewerbungsformular } from '../helpers/downloads';

const ParticipationPage = () => (
    <BaseLayout
        title="Online-Bewerbung"
        description="Online-Bewerbungsformular für den LOTTO Sportjugend-Förderpreis 2020"
        disablePrefooter
    >
        <Stage>
            <StageIntro
                headline={phased([
                    { text: 'Jetzt online bewerben', smaller: true },
                    { text: 'Vielen Dank für Ihr Interesse', smaller: true },
                    { text: 'Die Gewinner sind ermittelt', smaller: true },
                ])}
                copy={phased([
                    'Bewerbung bis zum 11.01.2021 einreichen und die Chance auf bis zu 7.500 € Preisgeld für Euren Verein sichern!',
                    'Die Bewerbungsphase ist abgeschlossen. Die Jury wird die Gewinner ermitteln',
                    'Die Gewinner sind ermittelt',
                ])}
            />
        </Stage>

        <Section container="s" textAlign="center" gap="none">
            <Headline level="h2">LOTTO AWARD – Sportjugend-Förderpreis 2020</Headline>
            {phased([
                <>
                    <P>
                        Bitte füllt möglichst alle Felder aus und beachtet die Hinweise. Bitte prüft
                        alle Eingaben vor dem Absenden auf Vollständigkeit. Die mit (*)
                        gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.{' '}
                        <strong>
                            Ihr könnt eure Bewerbung jederzeit speichern und die Bearbeitung zu
                            einem späteren Zeitpunkt fortsetzen.
                        </strong>
                        <br /> <strong>Wir wünschen viel Erfolg!</strong>
                    </P>
                    <P>
                        <small>
                            Hilfe beim Ausfüllen des Formulars leistet<br />
                            <strong>
                                <Link
                                    type="download"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={downloadsSjfpLeitfadenBewerbungsformular}
                                >
                                    unser Bewerbungsleitfaden
                                </Link>
                            </strong>
                        </small>
                    </P>
                </>,
                <P>Die Bewerbungsphase ist abgeschlossen.</P>,
            ])}
        </Section>

        {phased([<RegistrationForm />])}
    </BaseLayout>
);

export default ParticipationPage;
