/**
 * Validatoren für die Formularfelder: Hier ist der Name Programm
 * ----------------------------------------------------------------------------
 */

import { isNumeric, isLength, isISO8601, isEmail, isURL, isEmpty } from 'validator';
import AgeRange from './AgeRange';

const stdError = 'Bitte Feld ausfüllen oder gültigen Wert angeben';

export const required = value => !value && 'Bitte Feld ausfüllen';

export const requiredMinMax = (min, max) => value =>
    (isEmpty(value, { ignore_whitespace: true }) || !isLength(value, { min, max })) && stdError;

export const requiredMin1Max32 = requiredMinMax(1, 32);
export const requiredMin3Max32 = requiredMinMax(3, 32);
export const requiredMin3Max64 = requiredMinMax(3, 64);
export const requiredMin3Max96 = requiredMinMax(3, 96);
export const requiredMin3Max128 = requiredMinMax(3, 128);
export const requiredMin3Max512 = requiredMinMax(3, 512);

export const requiredEmail = value =>
    (!value || !isEmail(value)) && 'Bitte gültige E-Mail eingeben';

export const requiredDate = value =>
    (!value || !isISO8601(value)) && 'Bitte gültiges Datumsformat eingeben';

export const requiredArray = value => !value.length && 'Bitte Option wählen';

export const requiredNumeric = value =>
    !isNumeric(String(value), { no_symbols: true }) && 'Bitte Feld ausfüllen oder Zahl angeben';

export const optionalNumeric = value => {
    if (!value) {
        return undefined; // valid
    }
    return requiredNumeric(value);
};

export const optionalUrl = value => {
    if (!value) {
        return undefined; // valid
    }
    if (!isURL(value, { require_protocol: true })) {
        return 'Bitte geben Sie eine gültige URL mit "http://" an.'; // invalid
    }
    return undefined; // valid
};

export const optionalMin3Max512 = value => {
    if (!value) {
        return undefined; // valid
    }
    if (!isLength(value, { min: 3, max: 512 })) {
        return stdError;
    }
    return undefined; // valid
};

export const optionalMin3Max32 = value => {
    if (!value) {
        return undefined; // valid
    }
    if (!isLength(value, { min: 3, max: 32 })) {
        return stdError;
    }
    return undefined; // valid
};

// AgeRange Validatoren
// Spezielle Prüfung ob das "bis" Feld einen größeren Wert enthält
const checkAgeRange = value => {
    const { valueFrom, valueTo } = AgeRange.parseValue(value);

    if (parseInt(valueTo, 10) < parseInt(valueFrom, 10) || valueTo === '' || valueFrom === '') {
        return 'Bitte geben Sie im "bis" Feld eine größere Zahl ein';
    }

    return undefined; // valid
};

export const requiredAgeRange = value => {
    const requiredCheck = required(value);
    if (requiredCheck) {
        return requiredCheck;
    }

    return checkAgeRange(value);
};

export const optionalAgeRange = value => {
    if (!value) {
        return undefined; // valid
    }

    return checkAgeRange(value);
};
