import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { inputStyles, ErrorMessage, Label, Wrapper } from './Input';

import { remCalc, mq } from '../../helpers/stylehelpers';

const SelectWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

/** @type {styledComponent} Select */
const SelectField = styled.select`
    ${inputStyles};

    padding: calc(0.5em - 1px);
`;

const Text = styled.span`
    font-size: ${remCalc(16)};
    margin-left: ${({ ml = 'inherit' }) => ml};
    margin-right: ${({ mr = 'inherit' }) => mr};

    ${mq.medium`
        font-size: ${remCalc(18)};
    `};
`;

/**
 * Altersspannen-Komponente
 * ----------------------------------------------------------------------------
 * @param {string} props.field Formik Field
 * @param {string} props.form Formik Form
 * @param {string} props.required Optional: Gibt an, ob Feld Pflichtfeld ist
 * @param {string} props.label Label des Elements
 * @param {string} props.gap Optional: Label des Elements
 * @param {string} props.gridColStart Optional: Mögliche Startposition im Grid
 * @param {string} props.gridColEnd Optional: Mögiche Endposition im Grid
 *
 * @example <AgeRange name="foo" label="Foo" />
 */
class AgeRange extends Component {
    /**
     * Holt die Zahlen aus dem Wert von Formik
     * Formik bekommt einen String "x - y" aber die AgeRange Komponente
     * braucht x und y als einzelne Werte
     * @param {String} value Value im Formikstate
     * @return {Object}
     */
    static parseValue(value) {
        const match = value.match(/(\d+) - (\d+)/);

        if (!match || match.length !== 3) {
            return { valueFrom: '', valueTo: '' };
        }

        return { valueFrom: match[1], valueTo: match[2] };
    }

    static propTypes = {
        required: PropTypes.bool,
        label: PropTypes.string.isRequired,
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }).isRequired,
        form: PropTypes.shape({
            errors: PropTypes.object,
            touched: PropTypes.object,
        }).isRequired,
        gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        gridColStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        gridColEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    static defaultProps = {
        required: false,
        gap: null,
        gridColStart: null,
        gridColEnd: null,
    };

    /**
     * Konstruktor
     * @param {Object} props Props
     */
    constructor(props) {
        super(props);

        const { valueFrom, valueTo } = AgeRange.parseValue(props.field.value);

        this.state = {
            valueFrom,
            valueTo,
        };
    }

    handleChange = stateName => evt => {
        this.setState(
            {
                [stateName]: evt.target.value,
            },
            () => {
                const { valueFrom, valueTo } = this.state;
                const { form, field } = this.props;

                if (valueFrom !== '' && valueTo !== '') {
                    form.setFieldValue(field.name, `${valueFrom} - ${valueTo}`);
                    form.setFieldTouched(field.name, true);
                } else {
                    form.setFieldValue(field.name, '');
                }
            }
        );
    };

    /**
     * Rendert ein Feld um eine Altersspanne anzugeben
     */
    render() {
        const {
            field: { name },
            form: { errors, touched },
            required,
            label,
            gap,
            gridColStart,
            gridColEnd,
        } = this.props;

        const { valueFrom, valueTo } = this.state;

        // ErrorMessage holen, wenn Feld in errors aufgeführt wird
        const errorOccured = errors[name] && touched[name];
        const errorMessage = errorOccured && <ErrorMessage>{errors[name]}</ErrorMessage>;
        const labelMessage = label && (
            <span>
                {label}
                {required && ` *`}
            </span>
        );

        const options = [];
        for (let index = 0; index < 100; index += 1) {
            options.push(index);
        }

        return (
            <Wrapper gap={gap} gridColStart={gridColStart} gridColEnd={gridColEnd}>
                <Label>{labelMessage}</Label>
                <SelectWrapper>
                    <Text mr="0.5rem">von</Text>
                    <SelectField
                        onChange={this.handleChange('valueFrom')}
                        value={valueFrom}
                        showErrorBorder={errorOccured}
                    >
                        <option value="">Bitte wählen</option>
                        {options.map(number => (
                            <option key={number} value={number}>
                                {number}
                            </option>
                        ))}
                    </SelectField>
                    <Text mr="0.5rem" ml="0.5rem">
                        bis
                    </Text>
                    <SelectField
                        onChange={this.handleChange('valueTo')}
                        value={valueTo}
                        showErrorBorder={errorOccured}
                    >
                        <option value="">Bitte wählen</option>
                        {options.map(number => (
                            <option key={number} value={number}>
                                {number}
                            </option>
                        ))}
                    </SelectField>
                </SelectWrapper>
                {errorMessage}
            </Wrapper>
        );
    }
}
/* eslint-enable jsx-a11y/label-has-for */

export default AgeRange;
